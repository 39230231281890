import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { PortalService } from '../../rest';
import { Appointment } from '../../rest/model/appointment';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {

  public appointments: Appointment[] = [];
  public loading = false;

  constructor(public globals: Globals, private api: PortalService) {
    this.globals.globalTitle = 'Termine';
  }

  ngOnInit(): void {
    this.loadAppointments();
  }

  loadAppointments() {
      this.loading = true;
      
      const filter = "startDate,startTime";
      this.api.portalAppointmentsGet(filter).subscribe(
          (m: Appointment[]) => {
              this.appointments = m;

              this.loading = false;
          },
          error => {
              this.loading = false;
          }
      );
  }
}
