import {Injectable} from '@angular/core';
import {AuthConfig, OAuthService, TokenResponse, UrlHelperService} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment';
import {Globals} from '../globals';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private clientId: string;
    private redirectUri: string;
    private host: string;
    private authConfig: AuthConfig = {};

    constructor(private globals: Globals, private oauthService: OAuthService, protected http: HttpClient, protected urlHelper: UrlHelperService) {
        this.clientId = environment.clientId;
        this.redirectUri = environment.redirect == 'auto' ? window.location.origin + environment.subdir + '/callback' : environment.redirect;

        const savedHost = localStorage.getItem('ngUrl');
        if (savedHost !== undefined && savedHost !== null) {
            this.setHost(savedHost);
        }
    }

    private saveToStorage() {
        localStorage.setItem('access_token', this.globals.accessToken);
        localStorage.setItem('refresh_token', this.globals.refreshToken);
        localStorage.setItem('expires_at', '' + this.globals.expiresAt);
        localStorage.setItem('access_token_stored_at', '' + Date.now());
    }

    static clearStorage() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('access_token_stored_at');
    }

    private static expiresAt(expiresIn: string) {
        const expiresInMilliSeconds = parseInt(expiresIn, 10) * 1000;
        const now = new Date();
        return now.getTime() + expiresInMilliSeconds;
    }

    public setHost(host: string) {

        // Am Ende des Host muss ein / vorkommen, setzten falls nicht der Fall
        if (host.charAt(host.length - 1) !== '/') {
            host = host + '/';
        }

        // Host im local Storage speichern
        this.globals.debug('setting host in localStorage: ' + host);
        localStorage.setItem('ngUrl', host);

        // Credentials für OAuth aufbauen
        this.host = host;
        this.authConfig = {
            loginUrl: this.host + 'authorize.php',
            clientId: this.clientId,
            redirectUri: this.redirectUri,
            tokenEndpoint: this.host + 'token.php',
            responseType: 'code',
            scope: '',
            showDebugInformation: true
        };

        this.oauthService.configure(this.authConfig);
    }

    public redirectToLogin() {
        this.oauthService.initCodeFlow();
    }

    public getTokenFromCode(queryString?: string): Promise<boolean> {
        this.globals.debug('OAUTH - tokenFromCode based on native env: ' + (environment.native ? 'parseCodeFromQuery' : 'oauthService.tryLoginCodeFlow'));
        if(environment.native && queryString) {
            this.globals.debug('OAUTH - getting token from queryString' + queryString);
            return this.parseCodeFromQuery(queryString);
        } else {
            return this.oauthService.tryLoginCodeFlow().then(
                () => {
                    return true;
                },
                error => {
                    this.globals.error = 'Fehler während der Anfrage des Access Token (' + error.message + ').';
                    this.globals.debug('Error: ' + JSON.stringify(error));
                    return false;
                }
            );
        }
    }

    private parseCodeFromQuery(queryString): Promise<boolean> {
        const parts = this.urlHelper.parseQueryString(queryString);
        const code = parts['code'];
        const savedNonce = localStorage.getItem('nonce');
        const nonceInState = parts['state'];

        if (savedNonce !== nonceInState) {
            this.globals.debug('Validating access_token failed, wrong state/nonce.');
            return Promise.reject();
        }

        if (code) {
            return this.tokenHttpPost('getToken', code);
        } else {
            return Promise.resolve(false);
        }
    }

    public refreshToken(): Promise<boolean> {
        const expiresIn = (this.globals.expiresAt - (new Date()).getTime()) / 1000;
        this.globals.debug('token expires in ' + expiresIn + ' seconds');
        if (expiresIn <= 10) {
            this.globals.debug('Refreshing token');
            if(environment.native) {
                return this.tokenHttpPost('getRefreshToken', this.globals.refreshToken);
            } else {
                return this.oauthService.refreshToken().then(
                    (token: TokenResponse) => {
                        this.globals.debug('Refreshing token - success');
                        this.globals.accessToken = token.access_token;
                        this.globals.refreshToken = token.refresh_token;
                        this.globals.expiresIn = token.expires_in.toString();
                        return true;
                    },
                    err => {
                        this.globals.debug('error during OAuth2 refresh');
                        return false;
                    }
                );
            }
        } else {
            return new Promise((resolve) => {
                this.globals.debug('token does not need to be refreshed');
                resolve(true);
            });
        }
    }

    public revokeAll() {
        this.oauthService.logOut();
    }

    public loadFromStorage(displayError: boolean): boolean {
        const accessToken = this.oauthService.getAccessToken();
        const accessExpiration = this.oauthService.getAccessTokenExpiration();
        const refreshToken = this.oauthService.getRefreshToken();

        if (accessToken && refreshToken && accessExpiration) {
            this.globals.accessToken = accessToken;
            this.globals.refreshToken = refreshToken;
            this.globals.expiresAt = accessExpiration;
            // this.globals.expiresIn = expiresIn;

            this.globals.debug('access token found in storage: ' + this.globals.accessToken);
            this.globals.debug('refresh token found in storage: ' + this.globals.refreshToken);
            this.globals.debug('refresh token expires at: ' + this.globals.expiresAt);

            this.globals.debug('mandant in storage: ' + localStorage.getItem('ngUrl'));

            return true;
        } else {

            this.globals.debug('no tokens found in storage');
            if (displayError) {
                this.globals.error = 'Es wurde keine aktive Sitzung gefunden, bitte melden Sie sich neu an.';
            }
            return false;
        }
    }

    public tokenHttpPost(method: 'getToken' | 'getRefreshToken', codeOrToken: string): Promise<boolean> {

        const grantType = method === 'getToken' ? 'authorization_code' : 'refresh_token';
        const authType = method === 'getToken' ? 'code' : 'refresh_token';
        let params = new HttpParams()
            .set('grant_type', grantType)
            .set('redirect_uri', this.redirectUri)
            .set('client_id', this.clientId)
            .set(authType, codeOrToken);

        let headers = new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
        );

        return new Promise((resolve, reject) => {
            this.http
                .post<{access_token: string, refresh_token: string, expires_in: string}>(this.host + 'token.php', params, { headers })
                .subscribe(
                    tokenResponse => {
                        this.globals.debug(method + ' response: ' + JSON.stringify(tokenResponse));

                        this.globals.accessToken = tokenResponse.access_token;
                        this.globals.refreshToken = tokenResponse.refresh_token;
                        this.globals.expiresAt = AuthService.expiresAt(tokenResponse.expires_in);
                        this.saveToStorage();

                        resolve(true);
                    },
                    err => {
                        this.globals.debug('Error getting token: ' + JSON.stringify(err));

                        this.globals.loadingInfo = null;
                        this.globals.loggedIn = false;
                        this.globals.error = 'Fehler beim laden der Konfiguration, bitte melden Sie sich neu an.';

                        AuthService.clearStorage();

                        reject(err);
                    }
                );
        });
    }
}
