import {Component, HostListener, OnInit} from '@angular/core';
import {Globals} from '../../globals';
import {AuthService} from '../../global/auth.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslationService} from '../../global/translation.service';
import {ErrorHandlerService} from '../../global/error-handler.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    public logInStatus: string;
    public mandant: string;
    public mandantManual: string;
    public name: string
    public showManual = false;

    public showQR: boolean;

    private tService: TranslationService;

    static isValidURL(str): boolean {
        const a = document.createElement('a');
        a.href = str;
        return (window.location.protocol + '//' + a.host != window.location.origin && a.host.length > 0);
    }

    constructor(public globals: Globals, private router: Router, private auth: AuthService, private activatedRoute: ActivatedRoute,
                private translate: TranslateService, private http: HttpClient) {
        globals.globalTitle = 'Login';
        this.globals.setBackUrl(null);

        this.tService = TranslationService.getInstance(http);
        this.showQR = typeof navigator !== 'undefined' && !!navigator.mediaDevices;
    }

    ngOnInit() {
        this.tService.setTranslations([]);
        this.translate.reloadLang(this.globals.language);
        this.translate.use(this.globals.language);

        if (this.auth.loadFromStorage(false)) {
            // Falls im LocalStorage gültige Login-Angaben gefunden wurden,
            // dann wird der Benutzer auf die Startseite weitergeleitet
            this.router.navigate(['/start']);
        } else {
            // Die queyParams werden galaden, damit geprüft wird ob ein Mandant übergeben wurde
            this.activatedRoute.queryParams.subscribe((params: Params) => {

                const paramMandant = params['mandant'];
                const storageUrl = localStorage.getItem('ngUrl');

                if (paramMandant != null) {
                    // QueryParam-Mandant benutzen, falls übergeben
                    this.mandant = paramMandant;
                } else {
                    // Sonst schauen ob einer im localStorage gesetzt ist
                    if (storageUrl !== undefined && storageUrl !== null) {
                        this.mandant = storageUrl;
                    }
                }

                const paramName = params['name'];
                const storageName = localStorage.getItem('ngMandantName');

                if (paramName != undefined && paramName.length > 0) {
                    // QueryParam-Mandant benutzen, falls übergeben
                    this.name = paramName;
                } else if (storageUrl != undefined && storageName != undefined && storageName.length > 0) {
                    this.name = storageName;
                } else if (this.mandant != null) {
                    this.name = this.mandant.split('/').filter(s => s.length > 0).pop();
                }

                if (this.name != undefined && this.name != null) {
                    localStorage.setItem('ngMandantName', this.name);
                }

                const paramLogo = params['logo'];
                if (paramLogo != undefined) {
                    this.globals.setLogoUrl(paramLogo);
                }
            });
        }
    }

    removeMandant() {
      this.mandant = null;
      this.name = null;
      localStorage.removeItem('ngMandantName');
      localStorage.removeItem('ngUrl');
      localStorage.removeItem('ngLogo');

      this.globals.setLogoUrl(null);

      this.showManual = false;
    }

    logIn(manual = false) {

        const host = manual ? this.mandantManual : this.mandant;
        let mandant = null;

        if(host.match(/^[a-zA-Z\-]*$/g)) {
            mandant = 'https://www.taginet.com/' + host;
            this.globals.debug('mandant provided, added default www.taginet.com URL');
        } else if(LoginComponent.isValidURL(host)) {
            mandant = host;
            this.globals.debug('Valid URL provided');
        } else {
            this.globals.error = 'Ungültiger Mandant';
            return;
        }

        this.auth.setHost(mandant);
        this.auth.redirectToLogin();
    }

    scanQrCode() {
        this.router.navigate(['/qrcode']);
    }
}
