import {FormfieldBase} from './formfield-base';
import {BehaviorSubject, isObservable} from 'rxjs';

export class SelectFormfield extends FormfieldBase<string> {
    controlType = 'select';
    values: BehaviorSubject<{key: string, value: string}[]> = null;
    returnKeyVal: boolean = false;

    constructor(options: {} = {}) {
        super(options);

        this.returnKeyVal = options['returnKeyVal'] || false;

        if (options['values'] instanceof BehaviorSubject) {
            this.values = options['values'];
        } else if (isObservable(options['values'])) {
            this.values = new BehaviorSubject(null);
            options['values'].subscribe((vals: { key: string; value: string; }[]) => {
                this.values.next(vals);
            });
        } else {
            this.values = new BehaviorSubject(options['values'] || []);
        }
    }
}
