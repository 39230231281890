import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormfieldBase} from '../models/formfield-base';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-formfield',
    templateUrl: './formfield.component.html',
    styleUrls: ['./formfield.component.css']
})
export class FormfieldComponent implements OnInit {

    @Input() field: FormfieldBase<any>;
    @Input() form: FormGroup;
    @Output() valChange: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {
        if (this.form.controls[this.field.key]) {
            this.form.controls[this.field.key].valueChanges.subscribe(v => this.valChange.emit(v));
        }
    }

    get isValid() {
        if (!this.form.controls[this.field.key] || this.form.controls[this.field.key].disabled) {
            // Falls es das Feld nicht gibt (DynamicPairs) oder das Feld disabled ist, gibt's kein Validator
            return true;
        } else {
            return this.form.controls[this.field.key].valid;
        }
    }

    getErrorMsg() {

        if (!this.form.controls[this.field.key] || this.form.controls[this.field.key].disabled) {
            return null;
        }

        const errors = this.form.controls[this.field.key].errors;
        if (errors.required) {
            return 'Dieses Feld ist Pflicht';
        }

        if (errors.email) {
            return 'Ungültige E-Mail-Adresse';
        }

        if (errors.matDatepickerParse) {
            return 'Ungültiges Datum';
        }
    }
}
