<div class="content-centered">
    <mat-card *ngIf="messages" class="info-card" id="card">
        <mat-progress-bar class="info-card-loading" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        <mat-card-content>
            <div id="messagesWrapper" #messageWrapper>
                <app-message *ngFor="let m of messages" [message]="m"></app-message>
            </div>
            <div *ngIf="messages.length === 0">
                <p *ngIf="loading">{{ 'Die Nachrichten werden geladen' | translate }}.</p>
                <p *ngIf="!loading">{{ 'Es sind keine Nachrichten vorhanden.' | translate }}</p>
            </div>
            <div class="button-right" *ngIf="!showMsgInput">
                <button type="button" mat-button color="primary" (click)="loadMessages();"><fa-icon [icon]="['fas', 'sync']" class="menu-icon"></fa-icon></button>
                <button type="button" mat-raised-button color="primary" (click)="toggleNewMessage(true);" *ngIf="!globals.disableMessages">{{ 'Neue Nachricht' | translate }}</button>
            </div>
            <div #formWrapper>
                <form class="example-form" [formGroup]="form" #formDirective="ngForm" *ngIf="showMsgInput" (ngSubmit)="sendMsg(formDirective)" enctype="multipart/form-data">
                    <div id="inputWrapper" fxLayout fxLayout.xs="column wrap" fxLayoutGap="1em">
                        <mat-form-field fxFlex="calc(30%-1em)">
                            <input matInput placeholder="{{ 'Betreff' | translate }}" formControlName="betreff">
                            <mat-error>
                                {{ 'Bitte geben Sie einen Betreff an.' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="calc(70%-1em)">
                            <textarea matInput placeholder="{{ 'Nachricht' | translate }}" formControlName="nachricht" rows="1" cdkTextareaAutosize cdkAutosizeMaxRows="5"></textarea>
                            <mat-error>
                                {{ 'Bitte geben Sie eine Nachricht ein.' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <div style="display: inline-block; width: 50%;">
                            <button mat-mini-fab type="button" alt="{{ 'Datei anfügen' | translate }}" (click)="attachment.click();">
                                <mat-icon aria-label="{{ 'Datei anfügen' | translate }}">attach_file</mat-icon>
                            </button>
                            <div *ngIf="attachmentName" class="attachment-name">{{attachmentName}}</div>
                            <input type="file" formControlName="attachment" (change)="getBase64($event)" hidden #attachment>
                        </div>
                        <div style="display: inline-block; width: 50%; text-align: end;">
                            <button mat-button (click)="toggleNewMessage(false)">{{ 'Abbrechen' | translate }}</button>
                            <button mat-raised-button color="primary">{{ 'Senden' | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </mat-card-content>
    </mat-card>
</div>
