export class TableColumn {
    field: string;
    title: string;
    type: string;
    width: number;
    align: string;
    sortable: boolean;
    sortDirection: string;
    minWidthShow: number;

    constructor(options: {
        field: string,
        title: string,
        width: number,
        type?: string,
        align?: string,
        sortable?: boolean,
        sortDirection?: string,
        minWidthShow?: number
    }) {
        this.field = options.field;
        this.title = options.title;
        this.width = options.width;
        this.type = options.type || 'text';
        this.align = options.align || 'left';
        this.sortable = options.sortable || false;
        this.sortDirection = options.sortDirection;
        this.minWidthShow = options.minWidthShow;
    }
}