<div class="content-centered">

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('contract_header' | translate) !== 'contract_header'">
        <mat-card-content>
            <p [innerHTML]="'contract_header' | translate"></p>
        </mat-card-content>
    </mat-card>    

    <app-form [fields]="fields" [data]="data" [saveFnc]="this.saveFnc.bind(this)"></app-form>

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('contract_footer' | translate) !== 'contract_footer'">
        <mat-card-content>
            <p [innerHTML]="'contract_footer' | translate"></p>
        </mat-card-content>
    </mat-card>

</div>