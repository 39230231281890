import {Component, OnInit, ViewChild} from '@angular/core';
import {Contract, Group, Occupancy, OccupancyType, PortalService} from '../../rest';
import {ErrorHandlerService} from '../../global/error-handler.service';
import {Globals} from '../../globals';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {FormComponent} from '../../global/form/form.component';
import * as field from '../../global/form/models/formfield.index';
import {finalize, map} from 'rxjs/operators';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-contracts',
    templateUrl: './contract.component.html',
    styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {

    childId: string = null;
    contractId: string = null;
    fields: any[];
    data = null;

    @ViewChild(FormComponent, { static: true }) private form: FormComponent;

    constructor(private api: PortalService, private errorHandler: ErrorHandlerService,
                private route: ActivatedRoute, public globals: Globals, private translate: TranslateService) {
    }

    saveFnc(data) {
        const occupancies: Occupancy[] = [];
        data.belegungen.forEach(belegung => {
            if (!belegung[0] === null || !belegung[1]) {
                return;
            }
            occupancies.push({
                id: null,
                contractId: this.contractId === 'new' ? null : this.contractId,
                weekday: belegung[0],
                occupancyTypeId: belegung[1],
                groupId: belegung[2] || null
            });
        });

        const checkboxes = [];

        this.globals.checkboxesBelegung.forEach(cb => {
            checkboxes.push({key: cb.key, value: data[cb.key] ? '1' : '0'});
        });

        const contract: Contract = this.data;
        contract.id = this.contractId === 'new' ? null : this.contractId;
        contract.validUntil = '2030-12-31';
        contract.childId = this.childId;
        contract.occupancies = occupancies;
        contract.options = checkboxes;
        contract.status = this.contractId === 'new' ? 20 : this.data.status;
        contract.status = data.status == 1000 ? this.data.status : data.status;

        if (this.data.status <= 10) {
            contract.validFrom = this.data.validFrom;
            contract.remarks = this.data.remarks;
        } else {
            contract.validFrom = data.validFrom;
            contract.remarks = data.remarks;
        }

        const serviceCall = this.contractId === 'new' ?
            this.api.portalChildrenChildidContractsPost(this.childId, contract) :
            this.api.portalChildrenChildidContractsContractidPut(this.childId, this.contractId, contract);

        // nachdem der Service aufgerufen wurde, soll auf die übersichtsliste der Beiträge navigiert werden
        return serviceCall.pipe(
            finalize(() => this.globals.back())
        );
    }

    ngOnInit() {
        this.form.clear();
        this.globals.globalTitle = 'Vertrag';

        this.route.paramMap.subscribe((p: ParamMap) => {
                this.childId = p.get('childId');
                this.globals.setBackUrl('/child/' + this.childId + '/contracts');
                this.contractId = p.get('contractId');
                this.loadForm();
            }
        );
    }

    loadForm() {
        this.form.clear();
        if (this.contractId === 'new') {
            this.route.queryParamMap.subscribe(queryParams => {
                this.data = {validFrom: queryParams.get('validFrom')};
                this.fields = this.formIni(this.data);

                this.form.setData(this.data);
                this.form.setFields(this.fields);
            });
        } else {
            this.api.portalChildrenChildidContractsContractidGet(this.childId, this.contractId).subscribe(
                c => {
                    this.data = c;
                    this.fields = this.formIni(this.data);

                    this.form.setData(this.data);
                    this.form.setFields(this.fields);

                },
                error => {
                    this.errorHandler.handle(error);
                }
            );
        }
    }

    addDay() {
        this.form.addFields([
            new field.Date({key: 'validFrom', label: 'Von', required: true}),
            new field.Date({key: 'validUntil', label: 'Bis', required: true, nobreak: true}),
        ]);
    }

    /**
     * function that defines how dynamic Pairs should be generated in the FormFieldDynamicPairs-class
     */
    dynamicPair(occupancy?: Occupancy): field.Pair {
        const self = this;
        const occupancyTypes = self.globals.occupancyTypes.pipe(map((types: OccupancyType[]) => {
            return types ? [{key: null, value: '-'}]
                .concat(
                    types
                    .filter((type: OccupancyType) => {
                        return (type.status&4)==4;
                    })
                    .map((type: OccupancyType) => {
                        return {key: type.id, value: type.name};
                    })
                ) : [];
        }));
        const groups = self.globals.groups.pipe(map((grps: Group[]) => {
            return grps ? [{key: null, value: '-'}]
                .concat(grps.map((g: Group) => {
                    return {key: g.id, value: g.name};
                })) : [];
        }));

        const tage_values = [
            {key: null, value: '-'},
            {key: 1, value: 'Montag'},
            {key: 2, value: 'Dienstag'},
            {key: 3, value: 'Mittwoch'},
            {key: 4, value: 'Donnerstag'},
            {key: 5, value: 'Freitag'}
        ];

        const fields = [];
        fields.push(new field.Select({key: 'day', label: 'Wochentag', value: occupancy ? occupancy.weekday : null, values: tage_values}));
        fields.push(new field.Select({key: 'occupancy', label: 'Belegungsart', value: occupancy ? occupancy.occupancyTypeId : null, values: occupancyTypes}));

        if (this.globals.belegungGruppenangabe) {
           fields.push(new field.Select({key: 'group', label: 'Gruppe', value: occupancy ? occupancy.groupId : null, values: groups}));
        }

        return new field.Pair( fields, {key: 'fieldPair'});
    }

    formIni(data: Contract): field.FormfieldBase<any>[] {

        const occupancyList = [];
        if (data.occupancies) {
            data.occupancies.sort((o1: Occupancy, o2: Occupancy) => {
                return o1.weekday < o2.weekday ? -1 : 1;
            }).forEach((o: Occupancy) => {
                occupancyList.push(this.dynamicPair(o));
            });
        }

        const disabled = data.status <= 10;

        const dynamicPair = new field.DynamicPairs( occupancyList, () => {
            return this.dynamicPair();
            }, {key: 'belegungen', disabled: disabled });

        const fields = [
            new field.Date({key: 'validFrom', label: 'Von Vertrag', required: true, disabled: disabled }),
            new field.Colheader({key: 'belegungen', label: 'Belegungen Vertrag'}),
            dynamicPair,
            new field.Colheader({label: 'Bemerkungen', key: 'remarksgroup'}),
            new field.Textarea({key: 'remarks', label: 'Bemerkungen', disabled: disabled })
        ];

        const stati_options = [];

        if(data.status !== undefined) {
            stati_options.push({key: -97, value: this.translate.instant('Ablehnen')}); // Bei neuem Vertragsformular (data.status == undefined) soll kein 'Ablehnen' als Aktion verfügbar sein.
        }

        // Simples Update möglich bei Stati > 10
        if([17, 20].indexOf(data.status) >= 0 || data.status === undefined) {
            stati_options.unshift({key:1000, value: data.status === undefined ? this.translate.instant('Beantragen') : this.translate.instant('Speichern')}); // Update am Anfang hinzufuegen, so dass dies der ausgewählte default wert ist.

            // Gleich Checkboxen (z.B. 'AGBs akzeptieren') mitschicken, wenn Client-Config portal_contract_fullform gesetzt?
            if (this.globals.contractFullForm==true && this.globals.checkboxesBelegung.length > 0) {
                fields.push(new field.Colheader({label: 'Checkboxen', key: 'checkboxgroup'}));
                this.globals.checkboxesBelegung.forEach(cb => {
                    const val = data.options ? data.options.filter(v => v.key === cb.key) : [];
                    let checked = val.length > 0 ? val[0]['value'] === '1' : false;
                    if (data.status === undefined) {
                        checked = cb.default;
                    }
                    fields.push(new field.Checkbox({label: cb.description, key: cb.key, value: checked, required: cb.required, disabled: data.status < 2}));
                });
            }
        }

        // Akzeptieren möglich bei Status 15 (Gegenvorschlag von Backend) und Status 10 (prov. akzeptiert durch Backend)
        if([15, 10].indexOf(data.status) >= 0) {
            stati_options.push({key:1, value: this.translate.instant('Akzeptieren')});

            // Falls akzeptieren möglich, dann die globalen Checkboxen (z.B. 'AGBs akzeptieren') anzeigen
            if (this.globals.checkboxesBelegung.length > 0) {
                fields.push(new field.Colheader({label: 'Checkboxen', key: 'checkboxgroup'}));
                this.globals.checkboxesBelegung.forEach(cb => {
                    const val = data.options ? data.options.filter(v => v.key === cb.key) : [];
                    let checked = val.length > 0 ? val[0]['value'] === '1' : false;
                    fields.push(new field.Checkbox({label: cb.description, key: cb.key, value: checked, required: cb.required, disabled: data.status < 2}));
                });
            }
        }

        // Vorschlag möglich bei Status 15 (Gegenvorschlag von Backend)
        if([15].indexOf(data.status) >= 0) {
            stati_options.push({key:17, value: this.translate.instant('Vorschlag')});
        }

        fields.push(new field.Select(
            {key: 'status', label: 'Aktion:', required: true, value: stati_options[0].key, values: stati_options}
        ));

        return fields;
    }

}
