import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Globals} from '../globals';
import {AuthService} from './auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ActionResponse} from '../rest';
import {environment} from '../../environments/environment';

@Injectable()
export class ErrorHandlerService {

    constructor(private globals: Globals, private router: Router) {
    }

    handle(error) {
        if (!navigator.onLine) {
            this.globals.offline = true;
        } else if (error.status === 0) {
            this.globals.error = 'Der Server konnte nicht erreicht werden.';
            // if the error has a message to display, display it
        } else {
            let msg = '';
            if (error instanceof HttpErrorResponse) {
                msg = error.statusText + ' (' + error.status + ')';
            } else if ('message' in error && 'status' in error) {
                // ActionResponse ist ein interface, kann man also nicht mit instanceof überprüfen
                // Es wird also geschaut ob die beiden Properties dieses Interfaces vorhanden sind
                msg = error.message + ' (' + error.status + ')';
            } else {
                msg = 'Unbekannter Fehler';
            }

            if (environment.production) {
                this.logOut(error);
            } else {
                this.globals.error = msg;
            }
        }
    }

    logOut(error) {
        // Globals zurück setzen
        this.globals.loggedIn = false;
        this.globals.loadingInfo = null;

        if (this.globals.error === null) {
            // Falls nicht schon eine Fehlermeldung gesetzt wurde, die üebergebene Meldung setzen
            this.globals.error = error;
        }

        // Auf die Login-Seite wechseln
        this.router.navigate(['/login']);
    }

}
