import { Component, OnInit, Input } from '@angular/core';
import { Globals } from '../../globals';
import * as moment from 'moment';
import { Appointment } from 'src/app/rest/model/appointment';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {

  @Input() appointment: Appointment = null;
  public start_date = '';
  public end_date = '';
  public weekdayShort = '';
  public start_time = '';
  public end_time = '';
  
  constructor(public globals: Globals) { }

  ngOnInit(): void {
    this.start_date = moment(this.appointment.startDate, 'YYYY-MM-DD').locale(this.globals.language).format('ll');
    this.end_date = moment(this.appointment.endDate, 'YYYY-MM-DD').locale(this.globals.language).format('ll') === 'Invalid date' ? '' : moment(this.appointment.endDate, 'YYYY-MM-DD').locale(this.globals.language).format('ll');
    this.weekdayShort =  moment(this.appointment.startDate, 'YYYY-MM-DD').locale(this.globals.language).format('ddd');
    this.start_time =  moment(this.appointment.startTime, 'hh:mm:ss').locale(this.globals.language).format('LT');
    this.end_time =  moment(this.appointment.endTime, 'hh:mm:ss').locale(this.globals.language).format('LT') === 'Invalid date' ? '' : moment(this.appointment.endTime, 'hh:mm:ss').locale(this.globals.language).format('LT');
  }

}
