import * as field from './formfield.index';
import {FormfieldBase} from './formfield-base';
import {FormGroup, FormControl} from '@angular/forms';
import { FormfieldControlService } from './formfield-control.service';
import {TranslateService} from '@ngx-translate/core';

/**
 * needed for the cloneFunction passed by the constructor.
 * You are free to choose any number and type of function parameters, but the return type must be FormFieldPair
*/
type CloneFunction = () => FormFieldPair;

/**
 * The class FormFieldDynamicPairs contains an arbitrary number of FormFieldPairs.
 * The number of FormFieldParis can be changed dynamically
*/
export class FormFieldDynamicPairs extends FormfieldBase<string> {
    controlType = 'dynamicPairs';
    pairs: Map<string, FormFieldPair> = new Map<string, FormFieldPair>();
    counter = 0;
    /** anonymous clone function passed by the constructor */
    private cloneFunc;
    
    /**
     * @param initialRows first FormFieldPair that should be displayed. More can be added by the addFieldPair() method.
     * @param cloneFunc Clone function that defines how new FormFieldPairs should be generated
     * @param options (optional) options for the formfieldBase constructor
     */
    constructor (initialRows: FormFieldPair[], cloneFunc: CloneFunction,  options: {} = {}) {
        super(options);
        for (const row of initialRows) {
            this.counter++;
            row.key = row.key + this.counter;
            for (const f of row.getFields()) {
                f.disabled = this.disabled;
                f.key = f.key + this.counter;
            }
            this.pairs.set(row.key, row);
        }
        this.cloneFunc = cloneFunc;

        // eine leere Reihe für das Erfassen von neuen Elementen wird immer angezeigt
        this.addDynamicFieldPair();
    }

    /**
     * add FieldPair by clicking on the add-button
     * @param form form that needs to be updated
     */
    public addDynamicFieldPair (form?: FormGroup) {

        this.counter++;
        const pair: FormFieldPair = this.cloneFunc();
        pair.key = pair.key + this.counter;
        for (const f of pair.getFields()) {
            f.key = f.key + this.counter;
            f.disabled = this.disabled;
            if(this.tService) {
                f.label = this.tService.instant(f.label);
            }
            if (form) {
                form.addControl(f.key, new FormControl(FormfieldControlService.toFormGroup([f], {})));
            }
        }

        this.pairs.set(pair.key, pair);
    }

    public setTranslationService(tService: TranslateService) {
        super.setTranslationService(tService);
        this.pairs.forEach((pair: FormFieldPair) => {
            // schon bestehende Paare übersetzen
            pair.getFields().forEach((pairField: FormfieldBase<any>) => {
                if(pairField.label) {
                    pairField.label = this.tService.instant(pairField.label);
                }
            })
        });
    }

    public removeDynamicFieldPair (key: string, form: FormGroup) {
        for (const f of this.pairs.get(key).getFields()) {
            if (this.pairs.size === 1) {
                form.controls[f.key].setValue(null);
            } else {
                form.removeControl(f.key);
            }
        }
        if (this.pairs.size > 1) {
            this.pairs.delete(key);
        }
    }

    public checkIfNewRow(lastRow, form) {
        if (lastRow) {
            this.addDynamicFieldPair(form);
        }
    }

}


/**
 * The class FormFieldPair contains an Array of fields that are rendered in a row
*/
export class FormFieldPair extends FormfieldBase<string> {
    controlType = 'pair';
    private fields: FormfieldBase<any>[];

    /**
     * constructor
     * @param fields as many FormFields you want, pass them as an array
     * @param options options for the FormFieldPair class
     */
    constructor (fields: FormfieldBase<any>[], options: {} = {}) {
        super(options);
        this.fields = fields;
    }

    /**
     * get all the Fields in the FormFieldPair
     */
    public getFields() {
        return this.fields;
    }

    /**
     * get the width for the html, e.g. for 4 elements the width will be 25%
     */
    public getWidth() {
        return 'calc(' + 100 / this.fields.length + '% - 1em)';
    }
}
