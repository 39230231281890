<div class="content-centered">

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('additional_occupancy_list_header' | translate) !== 'additional_occupancy_list_header'">
        <mat-card-content>
            <p [innerHTML]="'additional_occupancy_list_header' | translate"></p>
        </mat-card-content>
    </mat-card>

    <app-table (rowClicked)="loadOccupancy($event)"
               (newClicked)="newOccupancy()"
               (delClicked)="deleteOccupancies($event)"></app-table>

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('additional_occupancy_list_footer' | translate) !== 'additional_occupancy_list_footer'">
        <mat-card-content>
            <p [innerHTML]="'additional_occupancy_list_footer' | translate"></p>
        </mat-card-content>
    </mat-card>
</div>