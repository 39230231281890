<mat-card class="info-card" [style.maxWidth]="maxWidth">
    <mat-progress-bar class="info-card-loading" mode="indeterminate" *ngIf="!form"></mat-progress-bar>
    <mat-card-content>
        <form (ngSubmit)="onSubmit()" *ngIf="form; else noData" [formGroup]="form" fxLayout="column">

            <div *ngFor="let group of groups">
                <div *ngFor="let row of group" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0px">
                    <div *ngFor="let f of row" [fxHide]="f.hidden" [ngStyle.gt-xs]="{'max-width': f.fxMaxWidth}"
                         [fxFlex]="f.colspan + ' ' + f.colspan + ' ' + f.fxMaxWidth" [fxFlex.xs]="'1 1 100%'">
                        <app-formfield [field]="f" [form]="form"></app-formfield>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid || form.pristine">{{ 'Speichern' | translate }}</button>
            </div>
        </form>
        <ng-template #noData>
            {{ 'Das Formular wird geladen...' | translate }}
        </ng-template>
    </mat-card-content>
</mat-card>