import {Injectable} from '@angular/core';
import {FormfieldBase} from './formfield-base';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import { FormFieldDynamicPairs, FormFieldPair } from './formfield-dynamic-pairs';

@Injectable()
export class FormfieldControlService {
    constructor() { }

    static toFormGroup(fields: FormfieldBase<any>[], data: {} ) {
        let group: any = {};

        fields.forEach((field: FormfieldBase<any>) => {
            if (field instanceof FormFieldDynamicPairs) {
                field.pairs.forEach((value: FormFieldPair, key: string) => {
                    for (const f of value.getFields()) {
                        const fGroup = this.fieldToFormGroup(f, data);
                        group = {...group, ...fGroup};
                    }
                });
                return;
            } else {
                const fieldGroup = this.fieldToFormGroup(field, data);
                group = {...group, ...fieldGroup};
            }
        });

        return new FormGroup(group);
    }

    private static fieldToFormGroup(field: FormfieldBase<any>, data: {}) {
        const group: any = {};
        if (!field.value) {
            // Falls kein expliziter Wert in der Formdefinition angegeben wurde, wird der Wert aus den übermittelten Daten ausgelesen
            field.value = data[field.key] || '';
        }

        let val = field.value;

        if (field.controlType === 'date' && val.length > 0) {
            val = moment(field.value, 'YYYY-MM-DD');
            field.value = moment(field.value, 'YYYY-MM-DD').format('DD.MM.YYYY');
        }

        const validators = [];

        if (field.required) {
            if (field.controlType === 'checkbox') {
                validators.push(Validators.requiredTrue);
            } else {
                validators.push(Validators.required);
            }
        }

        if (field.controlType === 'email') {
            validators.push(Validators.email);
        }

        group[field.key] = new FormControl({value: val || '', disabled: field.disabled}, validators);
        return group;
    }
}
