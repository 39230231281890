<div [formGroup]="form" *ngIf="field.controlType !== 'empty'">

    <span *ngIf="field.controlType === 'colheader'" class="colheader">{{ field.label }}</span>

    <button *ngIf="field.controlType === 'button'" mat-raised-button type="button" (click)="field.onchange()">{{ field.label }}</button>

    <mat-checkbox *ngIf="field.controlType === 'checkbox'" [formControlName]="field.key" [color]="'primary'"><span [innerHTML]="field.label"></span></mat-checkbox>

    <ng-container *ngIf="field.controlType !== 'colheader' && field.controlType !== 'button' && field.controlType !== 'checkbox' && field.controlType !== 'dynamicPairs'">
        <mat-form-field [ngSwitch]="field.controlType" [ngClass]="'formfield'">
            <input *ngSwitchCase="'text'" matInput [id]="field.key" [formControlName]="field.key" [placeholder]="field.label" value="{{field.value}}" [type]="text">

            <textarea *ngSwitchCase="'textarea'" matInput [id]="field.key" [formControlName]="field.key" [placeholder]="field.label">{{field.value}}</textarea>

            <input *ngSwitchCase="'email'" matInput [id]="field.key" [formControlName]="field.key" [placeholder]="field.label" value="{{field.value}}" [type]="email">

            <ng-container *ngSwitchCase="'date'">
                <input matInput [matDatepicker]="picker" [id]="field.key" [formControlName]="field.key" [placeholder]="field.label">
                <mat-datepicker-toggle matSuffix [for]="picker" class="date-toggle"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </ng-container>

            <mat-select [id]="field.key" *ngSwitchCase="'select'" [formControlName]="field.key" [placeholder]="field.label">
                <mat-option *ngFor="let v of field.values | async" [value]="v.key">{{v.value}}</mat-option>
            </mat-select>

            <mat-select [id]="field.key" *ngSwitchCase="'multiselect'" [formControlName]="field.key" [placeholder]="field.label" multiple>
                <mat-option *ngFor="let v of field.values | async" [value]="v.key">{{v.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="!isValid">{{ getErrorMsg() }}</mat-error>
        </mat-form-field>
    </ng-container>

    <ng-container *ngIf="field.controlType === 'dynamicPairs'">
        <ng-container *ngFor="let pair of field.pairs | keyvalue; last as lastRow">
            <div fxLayout fxLayout.xs="column wrap">
                <ng-container *ngFor="let f of pair.value.getFields(); last as lastField">
                    <div [fxFlex]="pair.value.getWidth()">
                        <app-formfield [field]="f" [form]="form" (valChange)="field.checkIfNewRow(lastRow, form)"></app-formfield>
                    </div>
                    <ng-container *ngIf="lastField && !field.disabled">
                        <!-- Am Ende der Reihe wird der löschen-Knopf, oder ein Platzhalter in der letzen Reihe angezeigt -->
                        <button type="button" mat-icon-button color="primary" (click)="field.removeDynamicFieldPair(pair.key, form)" *ngIf="!lastRow; else noicon">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <ng-template #noicon><div style="width: 40px; height: 40px"></div></ng-template>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</div>