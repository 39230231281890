import { Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwPush } from '@angular/service-worker';
import { Globals } from '../globals';
import { BehaviorSubject } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import {Router} from '@angular/router';
import { AuthGuardService } from '../routing/auth-guard.service';
import {ActionResponse, PortalService} from '../rest';

import { FCM } from '@capacitor-community/fcm';
import {PushNotifications, Token} from '@capacitor/push-notifications';


@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    public canSubscribe$ : BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(public swPush: SwPush, private globals: Globals, public snackBar: MatSnackBar, private router: Router,
                private authGuardService: AuthGuardService, private zone: NgZone, private portalApi: PortalService) {
        this.updateCanSubscribe(this.globals.pushSubscription);
    }

    /**
     * Funktion zum setzen der aktuellen Registration. Wird in den Globals und im LocalStorage gespeichert
     * und canSubscribe$ wird aktualisiert damit sich die Anzeige dementsprechend anpasst
     * @param type 
     */
    private updateCanSubscribe(type: string) {

        this.globals.debug('storing subscrition tye ' + type + ' in localStorage');

        // Globals-Objekt die Registration angeben, das wird dann auch im localStorage gespeicher
        this.globals.setPushRegistration(type);
        // Push ist aktiviert falls über ServiceWorker oder Capacitor registriert
        const pushSubscribed = this.globals.pushSubscription === 'swPush' || this.globals.pushSubscription === 'capPush' || this.globals.pushSubscription === 'devPush';

        // Neuer Wert publizieren: man kann sich registrieren falls man nicht 
        // schon registriert ist und ein Push-Typ unterstützt wird
        this.canSubscribe$.next(!pushSubscribed && this.subscriptionType() != 'none');

        if(pushSubscribed) {
            this.setUpNotificationListener();
        }
    }

    public subscriptionType(): string {
        if(Capacitor.isNativePlatform()) {
            // wenn die App nativ installiert ist, dann wird die Capacitor-Push-Library eingesetzt
            return 'capPush';
        } else if(this.swPush.isEnabled && ('PushManager' in window)) {
            // falls ServiceWorker installiert ist und PushManager verfügbar ist (bei Safari z.B. nicht),
            // dann wird die ServiceWorker-Push-Library eingesetzt
            return 'swPush';
        } else if (environment.envName === 'dev') {
            return 'devPush';
        } else {
            // sonst steht keine Push-Library zur Verfügung
            return 'none';
        }
    }

    public register() {
        switch(this.subscriptionType()) {
            case 'swPush':
                this.registerSwPush();
                break;
            case 'capPush':
                this.registerCapPush();
                break;
            case 'devPush':
                this.registerDummyPush();
                break;
            default:
                this.globals.debug('No subscription method found');
                break;
        }
    }

    private registerSwPush() {
        this.swPush.requestSubscription({
            serverPublicKey: environment.vapidPublicKey
        })
        .then(
            (sub: PushSubscription) => {
                this.saveToken(JSON.stringify(sub), 'swPush');
            },
            (reason: any) => {
                this.globals.debug('rejected because: ' + reason);
                this.updateCanSubscribe('none');
            }
        )
        .catch(err => {
            this.globals.debug("Could not subscribe to notifications" + JSON.stringify(err));
            this.showMsg('Es ist ein Fehler aufgetreten');
            this.updateCanSubscribe('none');
        });
    }

    private registerCapPush() {

        PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register().then(reg => {
                    FCM.getToken()
                        .then((r) => {
                            this.saveToken(r.token, 'capPush');
                        })
                        .catch((err) => {
                            this.globals.debug("Could not register to notifications" + JSON.stringify(err));
                            this.showMsg('Es ist ein Fehler aufgetreten');
                            this.updateCanSubscribe('none');
                        });
                });
            } else {
                this.globals.debug("Could not register to notifications: " + JSON.stringify(result));
                this.showMsg('Es ist ein Fehler aufgetreten');
            }
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                this.globals.debug("Could not register to notifications" + JSON.stringify(error));
                this.showMsg('Es ist ein Fehler aufgetreten');
                this.updateCanSubscribe('none');
            }
        );
    }

    private registerDummyPush() {
        this.saveToken('dummy_dev_token', 'devPush');
    }

    public unregister() {
        switch(this.globals.pushSubscription) {
            case 'swPush':
                this.unregisterSwPush();
                break;
            case 'capPush':
                this.unregisterCapPush();
                break;
            case 'devPush':
                this.unregisterDummyPush();
                break;
            default:
                this.globals.debug('No subscription method found');
                break;
        }
    }

    private unregisterSwPush() {
        this.swPush.unsubscribe().then(
            () => {
                this.deleteToken();
            },
            (err) => {
                if(err === 'Not subscribed to push notifications.') {
                    this.globals.debug('tried to unsubscribe while not being subscribed, bad config handling...' + JSON.stringify(err));
                    // for the user, we can pretend everything went ok !
                    this.showMsg('Benachrichtigungen wurden deaktiviert');
                } else {
                    this.globals.debug('failed to unsubscribe, maybe no subscription was set? ' + JSON.stringify(err));
                    this.showMsg('Es ist ein Fehler aufgetreten');
                }
                this.updateCanSubscribe('none');
            }
        ).catch(err => {
            this.globals.debug("Could not unsubscribe to notifications" + JSON.stringify(err));
            this.showMsg('Es ist ein Fehler aufgetreten');
            this.updateCanSubscribe('none');
        });
    }

    private unregisterCapPush() {
        this.deleteToken();
    }

    private unregisterDummyPush() {
        this.deleteToken();
    }

    public setUpNotificationListener() {
        switch(this.globals.pushSubscription) {
            case 'swPush':
                this.handleNotificationSwPush();
                break;
            case 'capPush':
                this.handleNotificationCapPush();
                break;
            case 'none':
                break;
            default:
                this.globals.debug('unhandled subscription type for NotificationListener');
                break;
        }
    }

    private handleNotificationSwPush() {
        this.swPush.notificationClicks.subscribe(
            ({action, notification}) => this.router.navigateByUrl(action)
        );
    }

    private handleNotificationCapPush() {
        // Show us the notification payload if the app is open on our device

        PushNotifications.addListener('pushNotificationReceived',
            notification => {
                this.showMsg(notification.body ?? 'Neue Nachricht');
                this.globals.debug('Push action received: ' + notification.body);
                this.authGuardService.loadNewMessages();
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            notification => {
                notification.notification.click_action
                this.globals.debug('Push action performed: ' + JSON.stringify(notification));
            }
        );
    }

    private saveToken(token, type) {
        this.globals.debug('Push Token to send to backend: ' + token + ' (' + type + ')');

        const pushToken = {
            token: token,
            type: type
        };

        this.portalApi.portalPushTokenPost(pushToken).subscribe(
            (resp: ActionResponse) => {
                this.globals.debug('POST /portal/pushToken: ' + resp.status + ' (' + resp.message + ')');
                if(resp.status === '0') {
                    localStorage.setItem('tnPushTokenId', resp.id);
                    this.updateCanSubscribe(type);
                    this.showMsg('Benachrichtigungen wurden aktiviert');
                } else {
                    localStorage.removeItem('tnPushTokenId');
                    this.updateCanSubscribe('none');
                    this.showMsg('Fehler beim Abonnieren');
                }
            }
        );
    }

    private deleteToken() {
        const tokenId = localStorage.getItem('tnPushTokenId');

        localStorage.removeItem('tnPushTokenId');
        this.updateCanSubscribe('none');
        this.showMsg('Benachrichtigungen wurden deaktiviert');

        if(tokenId !== null) {
            this.portalApi.portalPushTokenTokenIdDelete(tokenId).subscribe(
                (resp: ActionResponse) => {
                    this.globals.debug('DELETE /portal/pushToken/' + tokenId + ': ' + resp.status + ' (' + resp.message + ')');
                }
            )
        }
    }

    private showMsg(msg) {
        this.zone.run(() => {
            const snackBar = this.snackBar.open(msg, 'OK', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 5000
            });
            snackBar.onAction().subscribe(() => {
              snackBar.dismiss();
            })
          });
    }
}
