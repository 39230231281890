import {Component, OnInit, ViewChild} from '@angular/core';
import {TableComponent} from '../../global/table/table.component';
import {AdditionalOccupancy, Contract, Occupancy, PortalService} from '../../rest';
import {ErrorHandlerService} from '../../global/error-handler.service';
import {Globals} from '../../globals';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {of} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {TableColumn} from '../../global/table/models/table-column';
import {TableConfiguration} from '../../global/table/models/table-configuration';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-contract-list',
    templateUrl: './contract-list.component.html',
    styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent implements OnInit {

    childId: string = null;

    @ViewChild(TableComponent, { static: true }) table: TableComponent;

    constructor(private api: PortalService, private errorHandler: ErrorHandlerService,
                private route: ActivatedRoute, public globals: Globals, private router: Router, private translate: TranslateService) {
    }

    ngOnInit() {
        this.globals.globalTitle = 'Verträge';
        this.route.paramMap.subscribe(
            (p: ParamMap) => {
                this.childId = p.get('childId');
                this.tableIni();
            }
        );
    }


    getData(filter?, sort?, page?, perPage?) {
        return this.api.portalChildrenChildidContractsGet(this.childId).pipe(
            map((contracts): {
                id: string,
                validFrom: string,
                status: string,

                mo: string,
                di: string,
                mi: string,
                do: string,
                fr: string,

                noEdit: boolean
            }[] => {

                this.table.newClickable = !(contracts.filter((c: Contract) => c.status == 1).length == 1 && contracts.length > 0);

                // Daten werden umstrukturiert und sortiert
                return contracts
                    .map(c => {
                        return {
                            id: c.id,
                            validFrom: c.validFrom,
                            status: this.translate.instant(this.getStatus(c.status)),

                            mo: this.getWeekdayOccupancy(c.occupancies, 1),
                            di: this.getWeekdayOccupancy(c.occupancies, 2),
                            mi: this.getWeekdayOccupancy(c.occupancies, 3),
                            do: this.getWeekdayOccupancy(c.occupancies, 4),
                            fr: this.getWeekdayOccupancy(c.occupancies, 5),

                            noEdit: c.status <= 2
                        };
                    })
                    .sort((c1, c2) => {
                        return c1.validFrom < c2.validFrom ? -1 : 1;
                    });
            }),
            catchError(() => {
                this.globals.error = 'Fehler beim laden der Daten.';
                return of([]);
            })
        );
    }

    getStatus(status) {
        switch (status) {
            case 0:
                return 'Definitiv';
            case 1:
                return 'Unterschrieben';
            case 10:
                return 'Prov. akzeptiert';
            case 15:
                return 'Gegenvorschlag';
            case 17:
                return 'Vorschlag';
            case 20:
                return 'Angefragt';
        }
    }

    getWeekdayOccupancy(occupancies: Occupancy[], weekday: number): string {
        const dayOccupancies = occupancies.filter((o: Occupancy) => o.weekday == weekday);
        return dayOccupancies.length === 0 ? '-' : dayOccupancies.map(o => o.occupancyType.token).join(', ');
    }

    tableIni() {
        this.table.setConfiguration(new TableConfiguration({
            title: 'Verträge',
            clientProcessing: true
        }));
        this.table.setColumns([
            new TableColumn({field: 'validFrom', title: 'Von', type: 'datesql', width: 30, sortable: true}),
            new TableColumn({field: 'status', title: 'Status', type: 'text', width: 20, sortable: false}),

            new TableColumn({field: 'mo', title: 'Mo', type: 'text', width: 10, sortable: false}),
            new TableColumn({field: 'di', title: 'Di', type: 'text', width: 10, sortable: false}),
            new TableColumn({field: 'mi', title: 'Mi', type: 'text', width: 10, sortable: false}),
            new TableColumn({field: 'do', title: 'Do', type: 'text', width: 10, sortable: false}),
            new TableColumn({field: 'fr', title: 'Fr', type: 'text', width: 10, sortable: false}),
        ]);
        this.table.setDataSourceService(this.getData.bind(this));
    }

    loadContract(row) {
        if (row.status !== 'Definitiv') {
            this.router.navigateByUrl('child/' + this.childId + '/contract/' + row.id);
        }
    }

    newContract() {
        this.router.navigateByUrl('child/' + this.childId + '/contract/new');
    }
}
