<mat-card class="info-card login-form" *ngIf="!globals.loadingInfo">
    <mat-card-content>

        <div *ngIf="mandant; else noMandant">
            <div class="msg-line">{{ 'Wilkommen beim Eltern-Portal der' | translate }} {{ name }}.</div>
            <button mat-raised-button color="primary" (click)="logIn(false)">{{ 'Anmelden' | translate }}</button>
            <button mat-button color="primary" (click)="removeMandant()">{{ 'KiTa wechseln' | translate }}</button>
        </div>

        <ng-template #noMandant>
            <div class="msg-line">{{ 'Das Eltern-Portal ist mit keiner KiTa verknüpft.' | translate }}</div>
            <div class="msg-line">{{ 'Bitte benutzen Sie den Link auf der KiTa-Homepage oder fragen Sie bei Ihrer Kita nach dem gültigen Link für das Eltern-Portal.' | translate }}</div>
            <div class="msg-line">{{ 'Vielen Dank für Ihr Verständnis.' | translate }}</div>

            <div *ngIf="!showManual">
                <button mat-button color="primary" (click)="scanQrCode()" *ngIf="showQR">{{ 'QR Code scannen' | translate }}</button>
                <button mat-button color="primary" (click)="showManual = true;">{{ 'Mandant eingeben' | translate }}</button>
            </div>
            <div *ngIf="showManual">
                <mat-form-field class="full-width">
                    <input matInput type="url" placeholder="{{ 'Mandant' | translate }}" [(ngModel)]="mandantManual">
                </mat-form-field>
                <br>
                <button mat-raised-button color="primary" (click)="logIn(true)">{{ 'Anmelden' | translate }}</button>
                <button mat-button color="primary" (click)="showManual = false;">{{ 'Abbrechen' | translate }}</button>
            </div>
        </ng-template>
        
    </mat-card-content>
</mat-card>
