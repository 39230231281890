<mat-nav-list class="side-list">
    <a mat-list-item (click)="toggleMenu()">
        <ngx-avatar [name]="child.firstName + ' ' + child.lastName" [size]="20" [textSizeRatio]="2" class="menu-icon"></ngx-avatar>
        {{ child.firstName + ' ' + child.lastName }}
        <span class="space-filler"></span>
        <fa-icon [icon]="['fas', 'chevron-down']" [ngClass]="{'rotate': true, 'down': showMenu }"></fa-icon>
    </a>
</mat-nav-list>
<mat-nav-list *ngIf="showMenu" class="side-list" style="padding-left: 1em;">
    <a mat-list-item [routerLink]="'/child/' + child.id" (click)="onLinkClick()"
       routerLinkActive="route-active" [routerLinkActiveOptions]="{'exact': true}">
        <fa-icon [icon]="['fas', 'user']" class="menu-icon"></fa-icon>
        {{ 'Grunddaten Kind' | translate }}
    </a>
    <a mat-list-item [routerLink]="'/child/' + child.id + '/contracts'"
       routerLinkActive="route-active" (click)="onLinkClick()">
        <fa-icon [icon]="['far', 'calendar-day']" class="menu-icon"></fa-icon>
        {{ 'Verträge' | translate }}
    </a>
    <a mat-list-item [routerLink]="'/child/' + child.id + '/additionalOccupancies'"
       routerLinkActive="route-active" (click)="onLinkClick()" *ngIf="hasActiveContract">
        <fa-icon [icon]="['far', 'calendar-plus']" class="menu-icon"></fa-icon>
        {{ 'Zusatztage' | translate}}
    </a>
    <mat-divider></mat-divider>
</mat-nav-list>