import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TableColumn} from './models/table-column';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {TableDataSource} from './data-source/data-source.component';
import {Observable} from 'rxjs/Observable';
import {TableConfiguration} from './models/table-configuration';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ActionResponse} from '../../rest/model/actionResponse';

export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {

    @Input() columns: TableColumn[] = [];
    @Input() configuration: TableConfiguration = null;

    @Output() rowClicked: EventEmitter<any> = new EventEmitter();
    @Output() newClicked: EventEmitter<any> = new EventEmitter();
    @Output() delClicked: EventEmitter<any> = new EventEmitter();

    public displayedColumns: string[] = [];
    public dataSourceServer: TableDataSource = new TableDataSource();
    public dataSourceClient: MatTableDataSource<any> = new MatTableDataSource([]);
    public loading = new BehaviorSubject<boolean>(false);

    private sortable: boolean = false;
    private sortActive: string = null;
    private sortDirection: string = null;

    public colStyles = {};

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatTable, { static: true }) table: MatTable<any>;

    public offsetWidth: number = 0;

    public rowClickable: boolean = false;
    public newClickable: boolean = false;
    public delClickable: boolean = false;

    private subscription: Subscription;
    private service: any;

    public selection = new SelectionModel<any>(true, []);

    constructor(public snackBar: MatSnackBar, private el: ElementRef) {
    }

    public setDataSourceService(service: (filter?, sort?, page?, perPage?) => Observable<any[]>) {
        if (this.configuration.clientProcessing) {
            // Falls die Daten Client-Seitig bearbeitet werden, dann müssen wir diese hier nur einmal laden und die
            // DateSource mit diesen initialisieren
            this.loading.next(true);

            this.service = service;
            this.subscription = this.service().subscribe(data => {
                this.dataSourceClient = new MatTableDataSource(data);
                this.dataSourceClient.sort = this.sort;
                this.loading.next(false);
            });
        } else {
            // Falls die Daten Server-Seitig bearbeitet werden, dann müssen wir der DataSource mitteilen, wie diese an die
            // Daten kommt, und die initiale Daten laden
            this.dataSourceServer.setService(service);
            this.dataSourceServer.loadData();
        }
    }

    public reloadData() {
      this.selection.clear();
      if (this.configuration.clientProcessing) {
        // Falls die Daten Client-Seitig bearbeitet werden, dann müssen wir diese hier nur einmal laden und die
        // DateSource mit diesen initialisieren
        this.loading.next(true);
        this.subscription.unsubscribe();
        this.subscription = this.service().subscribe(data => {
          this.dataSourceClient = new MatTableDataSource(data);
          this.dataSourceClient.sort = this.sort;
          this.loading.next(false);
        });
      } else {
        this.dataSourceServer.loadData();
      }
    }

    ngOnInit() {

        this.rowClickable = this.rowClicked.observers.length > 0;
        this.newClickable = this.newClicked.observers.length > 0;
        this.delClickable = this.delClicked.observers.length > 0;

        if (this.delClickable) {
            this.displayedColumns.push('select');
        }

        if (this.columns && this.columns.length > 0) {
           this.setColumns(this.columns);
        }

        window.onresize = (e) => {
            this.checkTableWidth();
        };
        this.checkTableWidth();
    }

    private checkTableWidth() {
        this.offsetWidth = this.el.nativeElement.children[0].offsetWidth;
        let totalPercentage = 0;

        this.columns.forEach((col: TableColumn) => {
            if (!col.minWidthShow || col.minWidthShow <= this.offsetWidth) {
                totalPercentage += col.width;
            }
        });

        this.columns.forEach((col: TableColumn) => {
            if (!col.minWidthShow || col.minWidthShow <= this.offsetWidth) {
                this.colStyles[col.field] = {'max-width': 100 * col.width / totalPercentage + '%', 'display': 'flex'};
            } else {
                this.colStyles[col.field] = {'max-width': '0%', 'display': 'none'};
            }
        });
    }

    ngAfterViewInit() {

        // reset the paginator after sorting
        // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        // merge(this.sort.sortChange, this.paginator.page)
        //     .pipe(
        //         tap(() => this.loadLessonsPage())
        //     )
        //     .subscribe();
        if (!this.configuration.clientProcessing) {
            // Falls die Daten Server-Seitig sortiert / gefiltert werden sollen, dann müssen wir auf changes im Sort und Pagination
            // reagieren, und die Daten entsprechend neu laden
            this.sort.sortChange.subscribe(sort => {
                this.dataSourceServer.loadData(null, sort);
            });
        }
    }

    public setConfiguration(configuration: TableConfiguration) {
        if (configuration.clientProcessing) {
            // Falls Daten Client-Seitig sortiert / gefiltert werden, dann werden der DataSource die Sort- und Pagination-Objekt
            // übergeben, damit dieser die Daten entsprechend bearbeiten kann. Die Daten werden nur einmal geladen, die DataSource
            // braucht den Loading-Status also nicht zu bearbeiten
            this.dataSourceClient.sort = this.sort;
        } else {
            // Falls die Daten Server-Seitig bearbeitet werden, dann steuert die DataSource den Lade-Status
            this.dataSourceServer.loading$.subscribe(v => this.loading.next(v));
        }
        this.configuration = configuration;
    }

    public setColumns(columns: TableColumn[]) {
        this.columns = columns;
        this.displayedColumns = [];
        if (this.delClickable) {
            this.displayedColumns.push('select');
        }
        this.columns.forEach((col: TableColumn) => {
            if (col.sortable) {
                this.sortable = true;
            }
            if (col.sortDirection) {
                this.sortActive = col.field;
                this.sortDirection = col.sortDirection;
            }
            this.displayedColumns.push(col.field);
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        // const dataSource = this.clientProcessing ? this.dataSourceClient : this.dataSourceServer;
        const numSelected = this.selection.selected.length;
        const data = this.configuration.clientProcessing ?
                        this.dataSourceClient.data :
                        this.dataSourceServer.data;
        const numRows = data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        const data = this.configuration.clientProcessing ?
            this.dataSourceClient.data :
            this.dataSourceServer.data;
        this.isAllSelected() ?
            this.selection.clear() :
            data.forEach(row => this.selection.select(row));
    }

    message(message: string, action: string, autoClose: boolean = true) {
        const config = {};
        if (autoClose) {
            config['duration'] = 5000;
        }

        this.snackBar.open(message, action, config);
    }

    cellStyle(col) {

    }
}
