import {Component, OnInit, ViewChild} from '@angular/core';
import * as field from '../../global/form/models/formfield.index';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ActionResponse, Parent, PortalService} from '../../rest';
import {ErrorHandlerService} from '../../global/error-handler.service';
import {Globals} from '../../globals';
import {FormComponent} from '../../global/form/form.component';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-parents',
    templateUrl: './parents.component.html',
    styleUrls: ['./parents.component.css']
})
export class ParentsComponent implements OnInit {
    fields: any[];
    data: Parent = null;

    @ViewChild(FormComponent, { static: true }  ) private form: FormComponent;

    constructor(private api: PortalService, private errorHandler: ErrorHandlerService,
                public globals: Globals, private translate: TranslateService, private router: Router) {
    }

    saveFnc(data): Observable<ActionResponse> {
        data = {...this.data, ...data};
        return this.api.portalParentsPut(data).pipe(
            map((response: ActionResponse) => {
                    if (response.status === '0') {
                        if (data.status==5) {
                            this.router.navigateByUrl('child/new');
                        }
                        else {
                            this.loadForm();
                        }
                    }
                    return response;
                }
            )
        );
    }

    ngOnInit(): void {
        this.globals.globalTitle = 'Elterndaten';
        this.loadForm();
    }

    loadForm(): void {
        this.fields = null;
        this.api.portalParentsGet().subscribe(
            p => {
                this.data = p;
                this.fields = this.formIni(this.data);

                this.form.setData(this.data);
                this.form.setFields(this.fields);

                if (this.data.role) {
                  // Sichtbarkeit der Vater- / Mutter-Gruppen anhand der gelieferten Rolle bestimmen
                  this.roleChanged(this.data.role);
                }
            },
            error => {
                this.errorHandler.handle(error);
            }
        );
    }

    roleChanged(val): void {
        switch (val) {
            case 'Eltern':
                this.form.hideGroup('mutter', false);
                this.form.hideGroup('vater', false);
                this.form.setFieldRequired('motherLastName', true);
                this.form.setFieldRequired('motherFirstName', true);
                this.form.setFieldRequired('fatherLastName', true);
                this.form.setFieldRequired('fatherFirstName', true);
                this.form.setFieldRequired('motherMaritalStatus', true);
                this.form.setFieldRequired('fatherMaritalStatus', true);
                this.form.setFieldRequired('motherEmployer', true);
                this.form.setFieldRequired('fatherEmployer', true);
                this.form.setFieldRequired('motherWorkplace', true);
                this.form.setFieldRequired('fatherWorkplace', true);
                this.form.setFieldRequired('motherMobile', true);
                this.form.setFieldRequired('fatherMobile', false);
                break;
            case 'Konkubinat':
                this.form.hideGroup('mutter', false);
                this.form.hideGroup('vater', false);
                this.form.setFieldRequired('motherLastName', true);
                this.form.setFieldRequired('motherFirstName', true);
                this.form.setFieldRequired('fatherLastName', true);
                this.form.setFieldRequired('fatherFirstName', true);
                this.form.setFieldRequired('motherMaritalStatus', true);
                this.form.setFieldRequired('fatherMaritalStatus', true);
                this.form.setFieldRequired('motherEmployer', true);
                this.form.setFieldRequired('fatherEmployer', true);
                this.form.setFieldRequired('motherWorkplace', true);
                this.form.setFieldRequired('fatherWorkplace', true);
                this.form.setFieldRequired('motherMobile', true);
                this.form.setFieldRequired('fatherMobile', false);
                break;
            case 'Mutter':
                this.form.hideGroup('mutter', false);
                this.form.hideGroup('vater', true);
                this.form.setFieldRequired('motherLastName', true);
                this.form.setFieldRequired('motherFirstName', true);
                this.form.setFieldRequired('fatherLastName', false);
                this.form.setFieldRequired('fatherFirstName', false);
                this.form.setFieldRequired('motherMaritalStatus', true);
                this.form.setFieldRequired('fatherMaritalStatus', false);
                this.form.setFieldRequired('motherEmployer', true);
                this.form.setFieldRequired('fatherEmployer', false);
                this.form.setFieldRequired('motherWorkplace', true);
                this.form.setFieldRequired('fatherWorkplace', false);
                this.form.setFieldRequired('motherMobile', true);
                this.form.setFieldRequired('fatherMobile', false);
                break;
            case 'Vater':
                this.form.hideGroup('mutter', true);
                this.form.hideGroup('vater', false);
                this.form.setFieldRequired('motherLastName', false);
                this.form.setFieldRequired('motherFirstName', false);
                this.form.setFieldRequired('fatherLastName', true);
                this.form.setFieldRequired('fatherFirstName', true);
                this.form.setFieldRequired('motherMaritalStatus', false);
                this.form.setFieldRequired('fatherMaritalStatus', true);
                this.form.setFieldRequired('motherEmployer', false);
                this.form.setFieldRequired('fatherEmployer', true);
                this.form.setFieldRequired('motherWorkplace', false);
                this.form.setFieldRequired('fatherWorkplace', true);
                this.form.setFieldRequired('motherMobile', false);
                this.form.setFieldRequired('fatherMobile', true);
                break;
            case 'Zusatzkontakt':
                this.form.hideGroup('mutter', false);
                this.form.hideGroup('vater', true);
                this.form.setFieldRequired('motherLastName', true);
                this.form.setFieldRequired('motherFirstName', true);
                this.form.setFieldRequired('fatherLastName', false);
                this.form.setFieldRequired('fatherFirstName', false);
                this.form.setFieldRequired('motherMaritalStatus', false);
                this.form.setFieldRequired('fatherMaritalStatus', false);
                this.form.setFieldRequired('motherEmployer', false);
                this.form.setFieldRequired('fatherEmployer', false);
                this.form.setFieldRequired('motherWorkplace', false);
                this.form.setFieldRequired('fatherWorkplace', false);
                this.form.setFieldRequired('motherMobile', false);
                this.form.setFieldRequired('fatherMobile', false);
                break;
        }

        let labelT = val === 'Zusatzkontakt' ? 'Angaben zum Zusatzkontakt' : 'Angaben zur Mutter';
        labelT = this.translate.instant(labelT);

        this.form.getField('mutter').label = labelT;

    }

    formIni(data: Parent): field.FormfieldBase<any>[] {

        let fields: field.FormfieldBase<any>[] = [];
        if (data.status === 1  && (this.globals.parentFullForm == false)) {
            fields = [
                new field.Colheader({label: 'Kontaktangaben', key: 'kontakt'}),
                new field.Text({key: 'firstName', label: 'Vorname', required: true}),
                new field.Text({key: 'lastName', label: 'Nachname', required: true, nobreak: true}),
                new field.Text({key: 'salutation', label: 'Anrede', required: true}),
                new field.Select({key: 'role', label: 'Rolle', required: true, nobreak: true, values: [
                        {key: 'Eltern', value: this.translate.instant('Familie')},
                        {key: 'Konkubinat', value: this.translate.instant('Konkubinat')},
                        {key: 'Mutter', value: this.translate.instant('Alleinerziehende Mutter')},
                        {key: 'Vater', value: this.translate.instant('Alleinerziehender Vater')},
                        {key: 'Zusatzkontakt', value: this.translate.instant('Amt / Andere')}
                    ], onchange: this.roleChanged.bind(this)}),

                new field.Text({key: 'street', label: 'Strasse', required: true, colspan: 2}),
                new field.Text({key: 'zip', label: 'PLZ', required: true}),
                new field.Text({key: 'city', label: 'Ort', required: true, nobreak: true}),
                new field.Text({key: 'country', label: 'Land'}),

                new field.Email({key: 'email', label: 'E-Mail', required: true}),
                new field.Select({key: 'corrspondanceLanguage', label: 'Korrespondenzsprache', nobreak: true, required: true, values: [
                        {key: 'de', value: this.translate.instant('Deutsch')},
                        {key: 'en', value: this.translate.instant('Englisch')},
                        {key: 'fr', value: this.translate.instant('Französisch')}
                    ]}),

                new field.Colheader({label: 'Angaben zur Mutter', key: 'mutter'}),
                new field.Text({key: 'motherFirstName', label: 'Vorname'}),
                new field.Text({key: 'motherLastName', label: 'Nachname', nobreak: true}),
                new field.Date({key: 'motherBirthday', label: 'Geburtsdatum', nobreak: true}),
                new field.Select({key: 'motherMaritalStatus', label: 'Zivilstand', values: [
                        {key: null, value: '-'},
                        {key: 'ledig', value: this.translate.instant('Ledig')},
                        {key: 'verheiratet', value: this.translate.instant('Verheiratet')},
                        {key: 'getrennt', value: this.translate.instant('Getrennt')},
                        {key: 'geschieden', value: this.translate.instant('Geschieden')},
                        {key: 'wiederverheiratet', value: this.translate.instant('Wiederverheiratet')},
                        {key: 'konkubinat', value: this.translate.instant('Konkubinat')},
                        {key: 'verwittwet', value: this.translate.instant('Verwitwet')},
                    ]}),
                new field.Text({key: 'motherNationality', label: 'Nationalität', nobreak: true}),
                new field.Text({key: 'motherLanguages', label: 'Sprachen', nobreak: true}),
                new field.Text({key: 'motherMobile', label: 'Mobile'}),
                new field.Text({key: 'motherPhonePrivate', label: 'Telefon', nobreak: true}),
                new field.Email({key: 'motherEmailPrivate', label: 'E-Mail', nobreak: true}),

                new field.Colheader({label: 'Angaben zum Vater', key: 'vater'}),
                new field.Text({key: 'fatherFirstName', label: 'Vorname'}),
                new field.Text({key: 'fatherLastName', label: 'Nachname', nobreak: true}),
                new field.Date({key: 'fatherBirthday', label: 'Geburtsdatum', nobreak: true}),
                new field.Select({key: 'fatherMaritalStatus', label: 'Zivilstand', values: [
                        {key: null, value: '-'},
                        {key: 'ledig', value: this.translate.instant('Ledig')},
                        {key: 'verheiratet', value: this.translate.instant('Verheiratet')},
                        {key: 'getrennt', value: this.translate.instant('Getrennt')},
                        {key: 'geschieden', value: this.translate.instant('Geschieden')},
                        {key: 'wiederverheiratet', value: this.translate.instant('Wiederverheiratet')},
                        {key: 'konkubinat', value: this.translate.instant('Konkubinat')},
                        {key: 'verwittwet', value: this.translate.instant('Verwitwet')},
                    ]}),
                new field.Text({key: 'fatherNationality', label: 'Nationalität', nobreak: true}),
                new field.Text({key: 'fatherLanguages', label: 'Sprachen', nobreak: true}),
                new field.Text({key: 'fatherMobile', label: 'Mobile'}),
                new field.Text({key: 'fatherPhonePrivate', label: 'Telefon', nobreak: true}),
                new field.Email({key: 'fatherEmailPrivate', label: 'E-Mail', nobreak: true}),

            ];
        } else {
            fields = [
                new field.Colheader({label: 'Kontaktangaben', key: 'kontakt'}),
                new field.Text({key: 'firstName', label: 'Vorname', required: true}),
                new field.Text({key: 'lastName', label: 'Nachname', required: true, nobreak: true}),
                new field.Text({key: 'salutation', label: 'Anrede', required: true}),
                new field.Select({key: 'role', label: 'Rolle', required: true, nobreak: true, values: [
                        {key: 'Eltern', value: this.translate.instant('Familie')},
                        {key: 'Konkubinat', value: this.translate.instant('Konkubinat')},
                        {key: 'Mutter', value: this.translate.instant('Alleinerziehende Mutter')},
                        {key: 'Vater', value: this.translate.instant('Alleinerziehender Vater')},
                        {key: 'Zusatzkontakt', value: this.translate.instant('Amt / Andere')}
                    ], onchange: this.roleChanged.bind(this)}),

                new field.Text({key: 'street', label: 'Strasse', required: true, colspan: 2}),
                new field.Text({key: 'zip', label: 'PLZ', required: true}),
                new field.Text({key: 'city', label: 'Ort', required: true, nobreak: true}),
                new field.Text({key: 'country', label: 'Land'}),

                new field.Email({key: 'email', label: 'E-Mail', required: true}),
                new field.Select({key: 'corrspondanceLanguage', label: 'Korrespondenzsprache', nobreak: true, required: true, values: [
                        {key: 'de', value: this.translate.instant('Deutsch')},
                        {key: 'en', value: this.translate.instant('Englisch')},
                        {key: 'fr', value: this.translate.instant('Französisch')}
                    ]}),

                new field.Colheader({label: 'Angaben zur Mutter', key: 'mutter'}),
                new field.Text({key: 'motherFirstName', label: 'Vorname'}),
                new field.Text({key: 'motherLastName', label: 'Nachname', nobreak: true}),
                new field.Date({key: 'motherBirthday', label: 'Geburtsdatum', nobreak: true}),
                new field.Select({key: 'motherMaritalStatus', label: 'Zivilstand', values: [
                        {key: null, value: '-'},
                        {key: 'ledig', value: this.translate.instant('Ledig')},
                        {key: 'verheiratet', value: this.translate.instant('Verheiratet')},
                        {key: 'getrennt', value: this.translate.instant('Getrennt')},
                        {key: 'geschieden', value: this.translate.instant('Geschieden')},
                        {key: 'wiederverheiratet', value: this.translate.instant('Wiederverheiratet')},
                        {key: 'konkubinat', value: this.translate.instant('Konkubinat')},
                        {key: 'verwittwet', value: this.translate.instant('Verwitwet')},
                    ]}),
                new field.Text({key: 'motherNationality', label: 'Nationalität', nobreak: true}),
                new field.Text({key: 'motherLanguages', label: 'Sprachen', nobreak: true}),
                new field.Text({key: 'motherMobile', label: 'Mobile'}),
                new field.Text({key: 'motherPhonePrivate', label: 'Telefon', nobreak: true}),
                new field.Email({key: 'motherEmailPrivate', label: 'E-Mail', nobreak: true}),
                new field.Text({key: 'motherProfession', label: 'Beruf'}),
                new field.Text({key: 'motherEmployer', label: 'Arbeitsgeber', nobreak: true}),
                new field.Text({key: 'motherWorkplace', label: 'Arbeitsort', nobreak: true}),
                new field.Text({key: 'motherPhoneBusiness', label: 'Tel. Geschäft'}),
                new field.Email({key: 'motherEmailBusiness', label: 'E-Mail Geschäft', nobreak: true, colspan: 2}),

                new field.Colheader({label: 'Angaben zum Vater', key: 'vater'}),
                new field.Text({key: 'fatherFirstName', label: 'Vorname'}),
                new field.Text({key: 'fatherLastName', label: 'Nachname', nobreak: true}),
                new field.Date({key: 'fatherBirthday', label: 'Geburtsdatum', nobreak: true}),
                new field.Select({key: 'fatherMaritalStatus', label: 'Zivilstand', values: [
                        {key: null, value: '-'},
                        {key: 'ledig', value: this.translate.instant('Ledig')},
                        {key: 'verheiratet', value: this.translate.instant('Verheiratet')},
                        {key: 'getrennt', value: this.translate.instant('Getrennt')},
                        {key: 'geschieden', value: this.translate.instant('Geschieden')},
                        {key: 'wiederverheiratet', value: this.translate.instant('Wiederverheiratet')},
                        {key: 'konkubinat', value: this.translate.instant('Konkubinat')},
                        {key: 'verwittwet', value: this.translate.instant('Verwitwet')},
                    ]}),
                new field.Text({key: 'fatherNationality', label: 'Nationalität', nobreak: true}),
                new field.Text({key: 'fatherLanguages', label: 'Sprachen', nobreak: true}),
                new field.Text({key: 'fatherMobile', label: 'Mobile'}),
                new field.Text({key: 'fatherPhonePrivate', label: 'Telefon', nobreak: true}),
                new field.Email({key: 'fatherEmailPrivate', label: 'E-Mail', nobreak: true}),
                new field.Text({key: 'fatherProfession', label: 'Beruf'}),
                new field.Text({key: 'fatherEmployer', label: 'Arbeitsgeber', nobreak: true}),
                new field.Text({key: 'fatherWorkplace', label: 'Arbeitsort', nobreak: true}),
                new field.Text({key: 'fatherPhoneBusiness', label: 'Tel. Geschäft'}),
                new field.Email({key: 'fatherEmailBusiness', label: 'E-Mail Geschäft', nobreak: true, colspan: 2}),

                new field.Colheader({label: 'Bemerkungen', key: 'remarksgroup'}),
                new field.Textarea({key: 'remarks', label: 'Bemerkungen'}),

            ];
        }

        return fields;
    }
}
