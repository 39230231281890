import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from './auth-guard.service';
import {LoginComponent} from '../pages/login/login.component';
import {StartComponent} from '../pages/start/start.component';
import {CallbackComponent} from '../pages/callback/callback.component';
import {ParentsComponent} from '../pages/parents/parents.component';
import {ChildrenComponent} from '../pages/children/children.component';
import {ContractComponent} from '../pages/contract/contract.component';
import {AdditionalOccupancyComponent} from '../pages/additional-occupancy/additional-occupancy.component';
import {ContractListComponent} from '../pages/contract-list/contract-list.component';
import {AdditionalOccupanciesListComponent} from '../pages/additional-occupancies-list/additional-occupancies-list.component';
import {MessagesComponent} from '../pages/messages/messages.component';
import {QrcodeComponent} from '../pages/qrcode/qrcode.component';
import {SettingsComponent} from '../pages/settings/settings.component';
import { MealsComponent } from '../pages/meals/meals.component';
import { AppointmentsComponent } from '../pages/appointments/appointments.component';


const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'qrcode',
        component: QrcodeComponent
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: 'start',
        canActivate: [AuthGuardService],
        component: StartComponent
    },
    {
        path: 'parents',
        canActivate: [AuthGuardService],
        component: ParentsComponent
    },
    {
        path: 'child/:childId',
        canActivate: [AuthGuardService],
        component: ChildrenComponent
    },
    {
        path: 'child/:childId/contracts',
        canActivate: [AuthGuardService],
        component: ContractListComponent
    },
    {
        path: 'child/:childId/contract/:contractId',
        canActivate: [AuthGuardService],
        component: ContractComponent
    },
    {
        path: 'child/:childId/additionalOccupancies',
        canActivate: [AuthGuardService],
        component: AdditionalOccupanciesListComponent
    },
    {
        path: 'child/:childId/additionalOccupancy/:occupancyId',
        canActivate: [AuthGuardService],
        component: AdditionalOccupancyComponent
    },
    {
        path: 'messages',
        canActivate: [AuthGuardService],
        component: MessagesComponent
    },
    {
        path: 'meals',
        canActivate: [AuthGuardService],
        component: MealsComponent
    },
    {
        path: 'appointments',
        canActivate: [AuthGuardService],
        component: AppointmentsComponent
    },
    {
        path: 'settings',
        canActivate: [AuthGuardService],
        component: SettingsComponent
    },
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/login'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
