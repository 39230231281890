import {Component, NgZone, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';
import {MatDrawer} from '@angular/material/sidenav';
import {Globals} from './globals';
import {AuthService} from './global/auth.service';
import {Child, PortalService} from './rest';
import {TranslateService} from '@ngx-translate/core';
import {NotificationsService} from './global/notifications.service';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {SplashScreen} from '@capacitor/splash-screen';
import {StatusBar, Style} from '@capacitor/status-bar';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements  OnInit {
    public sidenav_mode = 'side';
    public logoUrlStd: string;

    @ViewChild('sidenav', { static: true }) private sidenav: MatDrawer;
    @ViewChild('scroller', { static: true }) private scroller;
    children: Child[] = [];

    constructor(private router: Router, private _ngZone: NgZone, public globals: Globals, private pushService: NotificationsService,
                private auth: AuthService, private api: PortalService, private translate: TranslateService) {
    }
    
    ngOnInit() {
        window.onresize = (e) => {
            this.checkMenu();
        };
        this.checkMenu();

        this.pushService.setUpNotificationListener();

        this.setUpTranslation();

        this.globals.scroller = this.scroller;

        SplashScreen.hide();

        if(environment.native) {
            StatusBar.setBackgroundColor({color: '#2971ad'});
            StatusBar.setStyle({style: Style.Dark});
        }

        App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
            this.globals.debug("App opened with URL: " + data.url);
            if(data.url.includes('/callback?')) {
                this.auth.getTokenFromCode(data.url.substring(data.url.indexOf('/callback?') + 10)).then(
                    success => this.capNavigate(success ? '/start' : '/login'),
                    () => this.capNavigate(['/login'])
                ).catch(
                    () =>  this.capNavigate(['/login'])
                )
            }
        });
    }

    private capNavigate(url) {
        this.globals.debug('redirecting cap to: ' + url);
        this._ngZone.run(
            () => this.router.navigateByUrl(url)
        );
    }

    imgError(event) {
        event.target.src = window.location.origin + environment.subdir + '/assets/logo.png';
        this.globals.setLogoUrl(null);
    }

    closeWarning() {
        this.globals.error = null;
    }

    logOut() {
        this.globals.debug('LogOut called');
        // Globals zurück setzen
        this.globals.clearData();
        this.auth.revokeAll();
        this.router.navigate(['/login']);
    }

    collapse() {
        if (this.sidenav_mode === 'over') {
            this.sidenav.close();
        }
    }

    checkMenu() {
        this._ngZone.run(() => {
            const w = window.innerWidth;
            if (w > 768) {
                this.sidenav.open();
                this.sidenav_mode = 'side';
            } else {
                this.sidenav.close();
                this.sidenav_mode = 'over';
            }
        });
    }

    private setUpTranslation() {
        const lang = localStorage.getItem('ngLanguage');
        if (lang === undefined) {
            this.translate.getBrowserLang();
        }

        if (lang === 'de' || lang === 'en' || lang === 'fr') {
            this.globals.language = lang;
        } else {
            this.globals.language = 'de';
        }

        this.translate.setDefaultLang('de');
        this.translate.use(this.globals.language);
    }
    
    public pageVisible(path) {
        return this.globals.allowedPages.indexOf(path) !== -1;
    }

}
