<div class="content-centered">
    <mat-card class="info-card">
        <mat-card-content>
            <p [innerHTML]="'Willkommen auf der tagiNet-Elternapp!' | translate"></p>
        </mat-card-content>
    </mat-card>

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('start_footer' | translate) !== 'start_footer'">
        <mat-card-content>
            <p [innerHTML]="'start_footer' | translate"></p>
        </mat-card-content>
    </mat-card>

</div>