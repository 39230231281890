import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/global/auth.service';
import { Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import {LoginComponent} from '../login/login.component';
import {HttpParams} from '@angular/common/http';

@Component({
    selector: 'app-qrcode',
    templateUrl: './qrcode.component.html',
    styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {
    constructor(private auth: AuthService, private router: Router, public globals: Globals) { }

    public error = null;

    ngOnInit(): void {
        this.globals.setBackUrl('/login');
        this.globals.globalTitle = "Zurück zum Login";
    }

    scanError(error) {
        this.error = error;
    }

    scanSuccess(resultString: string) {
        this.globals.debug("scanned: " + resultString);

        this.parseUrl(resultString);

        this.router.navigate(['/login']);
    }

    parseUrl(url) {

        this.globals.debug('Check QR-url: ' + url);

        if(!LoginComponent.isValidURL(url)) {
            // Wenn der gescannte QR-Code keine URL ist, abbrechen
            this.globals.error = 'Ungültiger QR-Code gescannt';
            return;
        }

        this.globals.debug('trying to parse url: ' + url);

        if(url.indexOf('login?') === -1) {
            // Der QR-Code welcher für die App verteilt wird, anthält ein link auf die Login-Seite der App.
            // Wenn dies nicht geparst wurde, dann wird die URL 1:1 übernommen
            this.auth.setHost(url);
            return;
        }

        const queryString = url.substr(url.indexOf('login?') + 6);
        this.globals.debug('queryString: ' + queryString);

        const httpParams = new HttpParams({ fromString: queryString });

        if(httpParams.get('mandant')) {
            // Wenn in der URL ein mandant Query-Parameter vorkommt, dann wird dieser als URL übernommen
            // Zusätzlich wird nach einem name- und einem logo-QueryPram gesucht
            const mandant = httpParams.get('mandant');
            const name = httpParams.get('name').replace('+', ' ');
            const logo = httpParams.get('logo');

            this.globals.debug('mandant: ' + mandant);
            this.globals.debug('name: ' + name);
            this.globals.debug('logo: ' + logo);

            this.auth.setHost(mandant);

            if(name) {
                localStorage.setItem('ngMandantName', name);
            }

            if(logo) {
                this.globals.setLogoUrl(logo);
            }
        } else {
            // Wenn in der Login-IRL kein Mandant als QueryParam angegeben ist, dann wird die URL 1:1 übernommen
            this.auth.setHost(url);
        }
    }

}
