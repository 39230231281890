import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Message} from '../../rest';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {

    public subjectField = new FormControl('', [Validators.required]);
    public contentField = new FormControl('', [Validators.required]);

    constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: Message) {
    }

    send() {
        if (!this.subjectField.invalid && !this.contentField.invalid) {
            this.dialogRef.close(this.data);
        }
    }
}
