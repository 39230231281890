import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Globals} from '../globals';
import {Child, ConfigurationService, Message, PortalService, Setting} from '../rest';
import {AuthService} from '../global/auth.service';
import {HttpClient} from '@angular/common/http';
import {ErrorHandlerService} from '../global/error-handler.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {Translation} from '../rest/model/translation';
import {TranslationService} from '../global/translation.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AuthGuardService implements CanActivate {
    
    private tService: TranslationService;

    constructor(private globals: Globals, private router: Router, private api: ConfigurationService, private portal: PortalService,
                private auth: AuthService, private http: HttpClient, private errorHandler: ErrorHandlerService, private translate: TranslateService) {
        this.tService = TranslationService.getInstance(http);
    }

    private parseSetting(s: Setting) {
        if (s.key === 'pages') {
            s.value.split(',')
                .forEach(p => this.globals.allowedPages.push(p));
        } else if (s.key.substring(0, 9) === 'adm_text_') {
            this.globals.texte[s.key.substring(9)] = s.value;
        } else if (s.key.substring(0, 24) === 'portal_beitrag_checkbox_') {
            const key = s.key.substring(24);
            if (key.substr(-1) === '!') {
                this.globals.checkboxesBelegung.push({
                    key: key.substring(0, key.length - 1),
                    required: true,
                    description: s.value,
                    default: false
                });
            } else if (key.substr(-1) === '1') {
                this.globals.checkboxesBelegung.push({
                    key: key,
                    required: false,
                    description: s.value,
                    default: true
                });
            } else {
                this.globals.checkboxesBelegung.push({
                    key: key,
                    required: false,
                    description: s.value,
                    default: false
                });
            }
            this.globals.texte[s.key.substring(9)] = s.value;
        } else if (s.key === 'portal_belegung_gruppenangabe') {
            this.globals.belegungGruppenangabe = s.value === '1';
        } else if (s.key === 'portal_child_fullform') {
            this.globals.childFullForm = s.value === '1'; 
        } else if (s.key === 'portal_parent_fullform') {
                this.globals.parentFullForm = s.value === '1';
        } else if (s.key === 'portal_contract_fullform') {
            this.globals.contractFullForm = s.value === '1';
        } else if (s.key === 'portal_add_wo_active_contract') {
            this.globals.additionalsWithoutActiveContract = s.value === '1';
        } else if (s.key === 'portal_disable_messages') {
            this.globals.disableMessages = s.value === '1';
        }
    }

    private loadChildMenu(forceReload) {
        this.globals.debug("LoadChildMenu called");
        if (this.globals.childrenForMenu.value === null || forceReload) {
            this.globals.debug("Loading children for display in menu");
            this.portal.portalChildrenGet().subscribe((kids: Child[]) => {
                this.globals.debug("Loaded " + kids.length + " children for display in menu");
                this.globals.childrenForMenu.next(kids);
            });
        }
    }


    private loadMandantConfiguration(subject: BehaviorSubject<any>, getFunction: () => Observable<any>) {
        getFunction().subscribe(v => {
                subject.next(v);
            });
    }

    private loadConfig(): Observable<boolean> {

        if (this.globals.configLoaded) {
            return of(true);
        }

        this.globals.debug('loadConfig() called');

        this.loadMandantConfiguration(this.globals.occupancyTypes, this.api.configOccupancyTypesGet.bind(this.api));
        this.loadMandantConfiguration(this.globals.foodMenues, this.api.configFoodMenuesGet.bind(this.api));
        this.loadMandantConfiguration(this.globals.foodAllergies, this.api.configFoodAllergiesGet.bind(this.api));
        this.loadMandantConfiguration(this.globals.vaccines, this.api.configVaccinesGet.bind(this.api));
        this.loadMandantConfiguration(this.globals.groups, this.api.configGroupsGet.bind(this.api));
        this.loadMandantConfiguration(this.globals.kindergartens, this.api.configKindergartenGet.bind(this.api));

        // portal_mandant_name und portal_mandant_logo aus confdb lesen
        
        return this.api.configClientSettingsGet().pipe(
            map((config: Setting[]) => {

                    this.globals.config = config;

                    config.forEach((s: Setting) => this.parseSetting(s));

                    this.globals.debug('config loaded:');
                    this.globals.debug(config);

                    this.globals.configLoaded = true;

                    return true;
                }
            ),
            catchError((err, caught) => {
                this.globals.error = 'Fehler beim laden der Konfiguration, bitte melden Sie sich neu an.';

                AuthService.clearStorage();

                return of(false);
            })
        );
    }

    public loadNewMessages() {
        this.globals.debug("loading messages for icon badge");
        const filter = "direction%3D%3Doutbound;isRead%3D%3D1";
        this.portal.portalMessagesGet(null, filter).subscribe(
            (msgs: Message[]) => {
                this.globals.debug('got ' + msgs.length + ' unread messages');
                this.globals.countUnreadMessages.next(msgs.length);
            }
        )
    }

    /**
     * Diese Funktion lädt die Übersetzungen aus dem tagiNet, und merged diese mit den Standard
     * Übersetzungen welche dem Translate-Service bereitgestellt werden
     */
    private loadTranslations() {

        if (this.globals.translationsLoaded) {
            this.globals.debug('translations already loaded, no need to reload');
            return;
        }

        this.globals.translationsLoaded = true;

        this.globals.debug("loading translations");
        this.api.configTranslationsGet(null, 'page%3D%3D30299').subscribe(
            (t: Translation[]) => {

                this.globals.debug("loaded " + t.length + "translations");

                this.globals.translationsLoaded = true;
        
                // Die Übersetzungen welche aus dem schulNetz geladen wurden werden dem Translate-Service übergeben
                this.tService.setTranslations(t);
        
                // Die Übersetzungen des Translate-Service müssen neu geladen werden da wir evtl welche ergänzt haben
                this.translate.reloadLang(this.globals.language);
                this.translate.use(this.globals.language);
            },
            (err) => {
                console.log('Error loading translations:');
            }
        );
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const routePath = route.url[0].path;

        // Damit der Benutzer sieht dass etwas passiert, wird der Ladebalken und ein Infotext angezeigt
        this.globals.loadingInfo = 'Ihre Sitzung wird geladen';
        this.globals.error = null;

        // Erstmal muss geprüft werden ob eine frühere Sitzung im Storage gespeichert wurde
        if (!this.auth.loadFromStorage(true)) {
            // Falls nichts gefunden wurde, muss der Benutzer sich neu einloggen
            return this.authError('nothing found in storage');
        }

        return fromPromise(this.auth.refreshToken())
            .pipe(mergeMap(
                (res: boolean) => {
                    if (!res) {
                        // wenn das Token nicht gültig ist und nicht erneuert werden konnte, wird
                        // der Benutzer auf die Startseite weitergeleitet
                        this.globals.debug("error while refreshing storage");
                        return of(this.authError('error while refreshing storage'));
                    } else {

                        this.globals.debug("Refresh Token wurde erfolgreich geladen oder musste nicht neu geladen werden");
                        // Das Laden der Kinder wird gestarted, wir brauchen hier aber nicht auf
                        // eine Antwort zu warten, dies läuft Parallel zum rest
                        this.loadChildMenu(!this.globals.loggedIn);
                        this.loadNewMessages();
                        this.loadTranslations();

                        // Falls das Token gültig ist, gilt der Benutzer als eingeloggt, es muss
                        // nun die App-Konfiguration geladen werden. Die wird durch die loadConfig()
                        // Funktion getan welche ein boolean-Observable liefert
                        this.globals.loggedIn = true;

                        return this.loadConfig();   // TODO: hier ebenfalls !this.globals.loggedIn übergeben?
                    }
                }
            ))
            .pipe(mergeMap(
                (res: boolean) => {
                    if (!res) {
                        // wenn das Token nicht gültig ist und nicht erneuert werden konnte, wird
                        // der Benutzer auf die Startseite weitergeleitet
                        return of(this.authError('error while refreshing storage'));
                    } else {

                        // Falls das Token gültig ist, gilt der Benutzer als eingeloggt
                        this.globals.loggedIn = true;
                        this.globals.loadingInfo = null;

                        // Auf diese Seite darf nur zugegriffen werden falls die route in den allowedPages ist
                        const pageAllowed = this.globals.allowedPages.indexOf(routePath) !== -1;

                        this.globals.debug("testing if page " + routePath + " is allowed");

                        return of(pageAllowed);
                    }
                }
            ));

    }

    /**
     * Funktion welche die Logik kapselt falls der Benutzer auf die Startseite geleitet werden soll:
     * - der Benutzer wird als nicht eingeloggt markiert
     * - das Ladebalken wird versteckt
     * - der Infotext wird zurückgesetzt
     * - eine Fehlermeldung wird angezeigt
     * - der Benutzer wird auf die Startseite weitergeleitet
     * @param {string} consoleMsg
     * @returns {boolean}
     */
    authError(consoleMsg: string): boolean {

        this.globals.debug(consoleMsg);
        this.globals.loadingInfo = null;

        if (!navigator.onLine) {
            this.globals.offline = true;
            return true;
        }

        this.errorHandler.logOut('Fehler beim wiederherstellen Ihrer Sitzung');
        return false;
    }
}
