import {NgModule} from '@angular/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
    faCalendarPlus,
    faCalendarDay,
    faPlusCircle,
    faPlus,
    faBars,
    faArrowLeft,
    faTrashAlt,
    faPaperPlane,
    faCog
} from '@fortawesome/pro-regular-svg-icons';

import {
    faSignOut,
    faPowerOff,
    faChevronDown,
    faUser,
    faUserFriends,
    faHome,
    faUserPlus,
    faEnvelope,
    fas
} from '@fortawesome/pro-solid-svg-icons';


@NgModule({
    declarations: [],
    imports: [
        FontAwesomeModule
    ],
    exports: [
        FontAwesomeModule
    ]
})
export class IconsModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIcons(faCalendarPlus);
        library.addIcons(faCalendarDay);
        library.addIcons(faPlusCircle);
        library.addIcons(faPlus);
        library.addIcons(faChevronDown);
        library.addIcons(faUser);
        library.addIcons(faUserFriends);
        library.addIcons(faPowerOff);
        library.addIcons(faSignOut);
        library.addIcons(faBars);
        library.addIcons(faHome);
        library.addIcons(faUserPlus);
        library.addIcons(faArrowLeft);
        library.addIcons(faTrashAlt);
        library.addIcons(faEnvelope);
        library.addIcons(faPaperPlane);
        library.addIcons(faCog);
    }
}
