<div class="content-centered">
    <mat-card *ngIf="data" class="info-card">
        <mat-card-content>
            <p [innerHTML]="'Hier bearbeiten Sie Ihre Kontaktdaten.' | translate"></p>
            <p *ngIf="data.status == 1" [innerHTML]="'Ihre Änderungen wurden entgegengenommen und werden so bald wie möglich bearbeitet.' | translate"></p>
            <p *ngIf="data.status == 2" [innerHTML]="'Ihre Änderungen wurden entgegengenommen und sind momentan in Bearbeitung.' | translate"></p>
            <p *ngIf="data.status == 4" [innerHTML]="'Ihre Mail-Adresse wurde noch nicht bestätigt.' | translate"></p>
            <p *ngIf="data.status == 5" [innerHTML]="'Bitte vervollständigen Sie Ihre persönlichen Angaben. Besten Dank.' | translate"></p>
        </mat-card-content>
    </mat-card>

    <app-form #clsform [saveFnc]="this.saveFnc.bind(this)"></app-form>

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('parents_footer' | translate) !== 'parents_footer'">
        <mat-card-content>
            <p [innerHTML]="'parents_footer' | translate"></p>
        </mat-card-content>
    </mat-card>
</div>