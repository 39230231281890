import {Pipe, PipeTransform} from '@angular/core';
import {Globals} from '../globals';

@Pipe({
    name: 'admText',
    pure: false
})
export class AdmTextPipe implements PipeTransform {

    constructor(private globals: Globals) {
    }

    transform(value: any, textKey): any {
        const admText = this.globals.texte[textKey];
        return admText && admText.length > 0 ? admText : value;
    }

}
