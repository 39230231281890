import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './routing/app-routing.module';

import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuardService} from './routing/auth-guard.service';
import {TranslationService} from './global/translation.service';
import {FormComponent} from './global/form/form.component';
import {FormfieldComponent} from './global/form/formfield/formfield.component';
import {FormfieldControlService} from './global/form/models/formfield-control.service';
import {Globals} from './globals';

import {ApiModule} from './rest';
import {ErrorHandlerService} from './global/error-handler.service';


import {MaterialModule} from './modules/material.module';
import {IconsModule} from './modules/icons.module';
import {ZXingScannerModule} from '@zxing/ngx-scanner';

import {LoginComponent} from './pages/login/login.component';
import {StartComponent} from './pages/start/start.component';
import {CallbackComponent} from './pages/callback/callback.component';
import {ParentsComponent} from './pages/parents/parents.component';
import { ChildrenComponent } from './pages/children/children.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChildMenuComponent } from './partials/child-menu/child-menu.component';
import { ContractComponent } from './pages/contract/contract.component';
import { AdditionalOccupancyComponent } from './pages/additional-occupancy/additional-occupancy.component';
import { TableComponent } from './global/table/table.component';
import { ContractListComponent } from './pages/contract-list/contract-list.component';
import {AvatarModule} from 'ngx-avatar';
import { AdditionalOccupanciesListComponent } from './pages/additional-occupancies-list/additional-occupancies-list.component';
import { AdmTextPipe } from './global/adm-text.pipe';
import { MessagesComponent } from './pages/messages/messages.component';
import { MessageComponent } from './partials/message/message.component';
import { MessageDialogComponent } from './partials/message-dialog/message-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { QrcodeComponent } from './pages/qrcode/qrcode.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { MealsComponent } from './pages/meals/meals.component';
import { MealComponent } from './partials/meal/meal.component';
import { AppointmentsComponent } from './pages/appointments/appointments.component';
import { AppointmentComponent } from './partials/appointment/appointment.component';
import { NotificationsDialogComponent } from './partials/notifications-dialog/notifications-dialog.component';
import { AuthInterceptor } from './global/auth-interceptor.service';

export function oAuthStorageFactory(): OAuthStorage { return localStorage; }

export function TransLoaderFactory(http: HttpClient) {
    return TranslationService.getInstance(http);
}

@NgModule({
    declarations: [
        AppComponent,
        FormComponent,
        FormfieldComponent,
        LoginComponent,
        StartComponent,
        ParentsComponent,
        CallbackComponent,
        ChildrenComponent,
        ChildMenuComponent,
        ContractComponent,
        AdditionalOccupancyComponent,
        TableComponent,
        ContractListComponent,
        AdditionalOccupanciesListComponent,
        AdmTextPipe,
        MessagesComponent,
        MessageComponent,
        MessageDialogComponent,
        QrcodeComponent,
        SettingsComponent,
        MealsComponent,
        MealComponent,
        AppointmentsComponent,
        AppointmentComponent,
        NotificationsDialogComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        OAuthModule.forRoot(),
        AppRoutingModule,
        NoopAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        IconsModule,
        ApiModule,
        FlexLayoutModule,
        AvatarModule,
        ServiceWorkerModule.register('custom-service-worker.js', { enabled: environment.production }),
        ZXingScannerModule,
        // Übersetzungs-Service
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (TransLoaderFactory),
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        Globals,
        AuthGuardService,
        ErrorHandlerService,
        FormfieldControlService,
        { provide : HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: OAuthStorage, useFactory: oAuthStorageFactory }
    ],
    entryComponents: [
        MessageDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
