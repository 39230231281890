import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from '../../global/auth.service';
import {Globals} from '../../globals';

@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private auth: AuthService, private globals: Globals) {
    }

    ngOnInit() {
        this.globals.loadingInfo = 'Ihre Anfrage wird bearbeitet...';
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            if (params['code'] !== undefined) {
                this.globals.debug('callback received code: ' + params['code']);
                this.auth.getTokenFromCode().then(
                    result => {
                        if (result) {
                            this.router.navigate(['/start']);
                        } else {
                            this.router.navigate(['/login']);
                        }
                    }
                );
            }
        });
    }

}
