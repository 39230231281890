import {Component, OnInit} from '@angular/core';
import {Globals} from '../../globals';
import {NotificationsService} from '../../global/notifications.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    constructor(public globals: Globals, public pushService: NotificationsService, private translate: TranslateService) {
        this.globals.globalTitle = 'Einstellungen';
    }

    ngOnInit(): void {
    }

    registerPush() {
        this.pushService.register();
    }

    unregisterPush() {
        this.pushService.unregister();
    };

    setLanguage() {
        localStorage.setItem('ngLanguage', this.globals.language);
        this.translate.use(this.globals.language);
    }
}
