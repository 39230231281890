<div class="content-centered">

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('appointments_header' | translate) !== 'appointments_header'">
        <mat-card-content>
            <p [innerHTML]="'appointments_header' | translate"></p>
        </mat-card-content>
    </mat-card>

    <mat-card *ngIf="appointments" class="info-card" id="card">
        <mat-progress-bar class="info-card-loading" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        <mat-card-content>
            <div id="appointmentsWrapper">
                <app-appointment *ngFor="let m of appointments" [appointment]="m"></app-appointment>
            </div>
            <div *ngIf="appointments.length === 0">
                <p *ngIf="loading">{{ 'Die Termine werden geladen' | translate }}.</p>
                <p *ngIf="!loading">{{ 'Es sind keine Termine vorhanden.' | translate }}</p>
            </div>
        </mat-card-content>
    </mat-card>

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('appointments_footer' | translate) !== 'appointments_footer'">
        <mat-card-content>
            <p [innerHTML]="'appointments_footer' | translate"></p>
        </mat-card-content>
    </mat-card>

</div>