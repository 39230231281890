import {FormfieldBase} from './formfield-base';

export class DateFormfield extends FormfieldBase<string> {
    controlType = 'date';
    values: {key: string, value: string}[] = [];

    constructor(options: {} = {}) {
        super(options);
        this.values = options['values'] || [];
    }
}
