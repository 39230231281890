import {AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Globals} from '../../globals';
import {ActionResponse, Message, PortalService} from '../../rest';
import * as moment from 'moment';
import {FormControl, FormGroup, Validators, FormGroupDirective} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AuthGuardService} from '../../routing/auth-guard.service';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, AfterViewInit, AfterViewChecked {

    @ViewChild('attachment') attachment: ElementRef;
    @ViewChild('messageWrapper') messageWrapper: ElementRef;
    @ViewChild('formWrapper') formWrapper: ElementRef;

    public messages: Message[] = [];
    public loading = false;
    form: FormGroup;
    public showMsgInput: boolean = false;

    private attachmentBase64: string = null;
    public attachmentName: string = null;

    constructor(public globals: Globals, private api: PortalService, public snackBar: MatSnackBar, private authGuardService: AuthGuardService) {
        this.globals.globalTitle = 'Nachrichten';
        this.form = new FormGroup({
            betreff: new FormControl('', Validators.required),
            nachricht: new FormControl('', Validators.required),
            attachment: new FormControl('')
        });
    }

    ngOnInit() {
        this.loadMessages();
    }

    loadMessages() {
        this.loading = true;
        this.api.portalMessagesGet().subscribe(
            (m: Message[]) => {
                this.messages = m;

                this.api.portalMessagesMarkAllAsReadPost().subscribe((res: ActionResponse) => {
                    if(res.status !== "0") {
                        //this.snackBar.open('Fehler beim Markieren der Nachrichten als gelesen.', null, {'duration': 5000});
                    }
                    this.authGuardService.loadNewMessages();
                });

                this.loading = false;
            },
            error => {
                this.loading = false;
            }
        );
    }

    sendMsg(formDirective: FormGroupDirective) {
        if (this.form.valid) {
            let trimAttachmentBase64 = null;
            if(this.attachmentBase64 !== null) {
                trimAttachmentBase64 = this.attachmentBase64.substring(this.attachmentBase64.indexOf(";base64,") + 8); // entfernen von "data:<mimetype>;base64," vor dem base64 encodeten string, da es sonst durch den Regex von Swagger fällt im Backend.
            }
            const msg: Message = {
                subject: this.form.value.betreff,
                content: this.form.value.nachricht,
                file: {
                    name: this.attachmentName,
                    content: trimAttachmentBase64,
                },
                direction: Message.DirectionEnum.Inbound
            };

            this.api.portalMessagesPost(msg).subscribe((res: ActionResponse) => {
                if(res.status !== "0") {
                    this.snackBar.open('Fehler beim Senden der Nachricht', null, {'duration': 5000});
                }
                this.loadMessages();
            });

            this.attachmentBase64 = null;
            this.attachmentName = null;

            formDirective.resetForm();
            this.form.reset();
            this.toggleNewMessage(false);
        }
        return false;
    }

    private getBase64(e) {
        const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

        if (file === undefined) {
            this.attachmentBase64 = null;
            this.attachmentName = null;
        }

        this.attachmentName = file.name;

        const reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        reader.readAsDataURL(file);
    }

    private _handleReaderLoaded(e) {
        this.attachmentBase64 = e.target.result;
    }

    private scrollToBottom() {
        this.messageWrapper.nativeElement.scrollTop = this.messageWrapper.nativeElement.scrollHeight;
    }

    public toggleNewMessage(show) {
        this.showMsgInput = show;
        setTimeout(() => {
            if (this.showMsgInput) {
                this.messageWrapper.nativeElement.style.maxHeight = 'calc(100vh - 140px - ' + this.formWrapper.nativeElement.offsetHeight + 'px)';
            } else {
                this.messageWrapper.nativeElement.style.maxHeight = 'calc(100vh - 190px)';
            }
        });
    }

    ngAfterViewInit(): void {
        this.scrollToBottom();
    }

    ngAfterViewChecked() {
        this.scrollToBottom();
    }
}
