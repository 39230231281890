import { FormFieldPair } from "./formfield-dynamic-pairs";
import {TranslateService} from '@ngx-translate/core';

export class FormfieldBase<T> {
    value: T;
    key: string;
    label: string;
    required: boolean;
    disabled: boolean;
    nobreak: boolean;
    hidden: boolean;
    controlType: string;
    onchange: Function;
    colspan: number;
    fxFlex: string;
    fxMaxWidth: string;
    pairs?: Map<string, FormFieldPair>;

    tService?: TranslateService;

    constructor(options: {
        value?: T,
        key?: string,
        label?: string,
        required?: boolean,
        disabled?: boolean,
        nobreak?: boolean,
        hidden?: boolean,
        controlType?: string
        onchange?: Function,
        colspan?: number;
    } = {}) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.disabled = options.disabled || false;
        this.nobreak = options.nobreak || false;
        this.hidden = options.hidden || false;
        this.controlType = options.controlType || '';
        this.onchange = options.onchange;
        this.colspan = options.colspan || 1;
    }

    public setTranslationService(tService: TranslateService) {
        this.tService = tService;
    }
}
