// globals.ts
import {Injectable} from '@angular/core';
import {Child, Group, OccupancyType, Setting, Kindergarten} from './rest';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';

@Injectable()
export class Globals {
    constructor(private router: Router) {
        const storageLogo = localStorage.getItem('ngLogo');
        this.setLogoUrl(storageLogo);

        this.pushSubscription = localStorage.getItem('tnPushSubscription');
    }

    serverURL: string;
    loggedIn = false;
    globalTitle = 'Start';

    pushSubscription: string;

    backURL: string = null;
    logoUrl: string;

    configLoaded: boolean = false;
    config: Setting[] = null;
    translationsLoaded: boolean = false;

    // Diese Seiten sind immer zugänglich. Weitere Seiten (menüplan, termine,...) können per Client-Config eingeschaltet werden
    allowedPages: String[] = ['start', 'parents', 'child', 'messages', 'settings'];

    texte: any = {};

    childrenForMenu = new BehaviorSubject<Child[]>(null);
    occupancyTypes = new BehaviorSubject<OccupancyType[]>(null);
    foodMenues = new BehaviorSubject<Setting[]>(null);
    foodAllergies = new BehaviorSubject<Setting[]>(null);
    vaccines = new BehaviorSubject<Setting[]>(null);
    groups = new BehaviorSubject<Group[]>(null);
    kindergartens = new BehaviorSubject<Kindergarten[]>(null);

    error: string = null;
    loadingInfo: string = null;

    offline: boolean = false;

    checkboxesBelegung = [];

    // OAuth2
    expiresIn: string;
    expiresAt: number;
    accessToken: string;
    refreshToken: string;

    belegungGruppenangabe = false;

    childFullForm = false;      // Volles Kinder-Formular auch bei Neu-Erfassungen
    parentFullForm = false;     // Volles Eltern-Formular auch bei Neu-Erfassungen
    contractFullForm = false;   // Volles Vertrags-Formular gleich bei der Erst-Erfassung
    additionalsWithoutActiveContract = false;   // Mehrbelegung ohne bestätigten Vertrag möglich?
    disableMessages = false;    // Deaktiviere den Nachrichtenversand durch Eltern

    scroller: any;

    countUnreadMessages = new BehaviorSubject<number>(0);

    language: string;

    public debug(msg: any) {
        if (environment.production === false) {
            console.log((environment.native ? "CAP-DEBUG: " : "") + msg);
        }
    }

    public setPushRegistration(registrationType: string) {
        this.pushSubscription = registrationType;
        localStorage.setItem('tnPushSubscription', registrationType);
    }

    public isValidURL(str): boolean {
        const a = document.createElement('a');
        a.href = str;

        const location = window.location;
        const currentHost = location.hostname + (location.port ? ':' + location.port : '');

        // Falls aus dem href des a-ELementes ein Host ermittelt werden konnte, und dieser nicht
        // dem Host der WebApp entspricht (manche Browser setzen den Host eines a-Elements automatisch auf)
        // den aktuellen Host falls das href nicht gültig ist
        return (a.host.length > 0 && a.host !== currentHost);
    }

    public setLogoUrl(url) {
        if (this.isValidURL(url)) {
            this.logoUrl = url;
        } else {
            this.logoUrl = window.location.origin + environment.subdir + '/assets/logo.png';
        }
        localStorage.setItem('ngLogo', this.logoUrl);
    }

    public setChildrenForMenu(children: Child[]) {
        this.childrenForMenu.next(children);
    }

    public clearData() {

        this.loggedIn = false;
        this.loadingInfo = null;
        this.translationsLoaded = false;

        this.config = null;
        this.configLoaded = false;
        this.allowedPages = ['start', 'parents', 'child', 'messages', 'settings'];

        this.childrenForMenu.next([]);
        this.occupancyTypes.next([]);
        this.foodMenues.next([]);
        this.foodAllergies.next([]);
        this.vaccines.next([]);
        this.kindergartens.next([]);

        this.accessToken = null;
        this.refreshToken = null;
        this.expiresAt = null;
    }

    public back() {
        const url = this.backURL;
        this.setBackUrl(null);
        this.router.navigateByUrl(url);
    }

    public setBackUrl(backUrl) {
        setTimeout(() => {
            // Als Timeout ausführen, damit dies erst nach dem aktuellen ChangeDetection-Zyklus läuft,
            // da sonst ein ExpressionChangedAfterItHasBeenCheckedError geworfen wird
            this.backURL = backUrl;
        });
    }

}
