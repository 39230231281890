export class TableConfiguration {
    title: string;
    clientProcessing: boolean = false;

    constructor(options: {
        title?: string,
        clientProcessing?: boolean
    }) {
        this.title = options.title;
        this.clientProcessing = options.clientProcessing || false;
    }
}
