import {Component, OnInit} from '@angular/core';
import {Globals} from '../../globals';
import {NotificationsService} from '../../global/notifications.service';
import {NotificationsDialogComponent} from '../../partials/notifications-dialog/notifications-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

    constructor(public globals: Globals, public pushService: NotificationsService, public dialog: MatDialog) {
        this.globals.globalTitle = 'Start';
    }

    ngOnInit() {
        if(localStorage.getItem('tnAskedForPushRegistration') !== 'yes') {
            this.askForNotifications();
        } else {
            this.globals.debug('Already asked for push registration once, no need to do so again');
        }
    }

    askForNotifications() {

        localStorage.setItem('tnAskedForPushRegistration', 'yes');

        const subType = this.pushService.subscriptionType();
        const canSub = this.pushService.canSubscribe$.value;

        this.globals.debug('subType: ' + subType + ', canSub: ' + canSub);

        if(subType !== 'none' && canSub) {
            const dialogRef = this.dialog.open(NotificationsDialogComponent);
            dialogRef.afterClosed().subscribe(result => {
                if(result) {
                    this.globals.debug('user confirmed push registration');
                    this.pushService.register();
                } else {
                    this.globals.debug('no push registration yet, maybe later...');
                }
            });
        }
    }

}
