<div [ngClass]="['message', directionClass]">
    <div [innerHTML]="message.subject" ngClass="subject"></div>
    <div ngClass="content" [innerHTML]="message.content"></div>
    <div ngClass="attachment" *ngIf="message.file">
        <mat-spinner ngClass="attach-spinner" [diameter]="30" *ngIf="isAttachFetching"></mat-spinner>
        <button mat-button (click)="requestAttachment();">
            <fa-icon [icon]="['fas', 'paperclip']"></fa-icon> {{ 'Anhang:' | translate }} {{message.file.name}}
        </button>
    </div>
    <div ngClass="timestamp"><fa-icon *ngIf="message.isRead && directionClass === 'inbound'" matTooltip="{{ 'Gelesen' | translate }}" [icon]="['fas', 'check-double']"></fa-icon> {{timestamp}}</div>
</div>