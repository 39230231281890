import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {TranslateLoader} from '@ngx-translate/core';
import {Translation} from '../rest/model/translation';
import {map, mergeMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TranslationService implements TranslateLoader {

    static instance: TranslationService;
    static isCreating: Boolean = false;

    private clientTranslationsLoaded: Subject<boolean>;
    private clientTranslations: Translation[] = [];

    static getInstance(http: HttpClient) {
        if (TranslationService.instance == null) {
            TranslationService.isCreating = true;
            TranslationService.instance = new TranslationService(http);
            TranslationService.isCreating = false;
        }

        return TranslationService.instance;
    }

    constructor(private http: HttpClient) {
        this.clientTranslationsLoaded = new ReplaySubject();
    }

    /**
     * Diese Funktion wird aufgerufen um die Übersetzungen aus dem schulNetz zu setzen
     * @param {Translation[]} trans
     */
    public setTranslations(trans: Translation[]) {
        this.clientTranslations = trans;
        // Wenn die Übersetzungen geladen wurden, wird angegeben dass diese nun zur verfügung stehen
        this.clientTranslationsLoaded.next(true);
    }

    public getTranslation(lang: string): Observable<any> {
        // Um die Übersetzungen ermitteln zu können, müssen wird erst mal warten dass die Übersetzungen vom
        // schulNetz geholt wurden, dies wird im ReplaySubject clientTranslationLoaded gekennzeichnet
        return this.clientTranslationsLoaded.pipe(mergeMap(() => {
            // Dann müssen wir die Standard-Übersetzungen aus dem Json-FIle in den assets lesen...
            let jsonUrl = window.location.origin + environment.subdir + '/assets/i18n/default_' + lang + '.json';

            return this.http.get(jsonUrl).pipe(map((translations) => {
                // ...und noch die schulNetz Übersetzungen ergänzen
                this.clientTranslations.forEach((t: Translation) => {
                    let clientTranslation = '';
                    switch (lang) {
                        case 'de':
                            clientTranslation = t.german;
                            break;
                        case 'fr':
                            clientTranslation = t.french;
                            break;
                        case 'en':
                            clientTranslation = t.english;
                            break;
                    }

                    if(clientTranslation && clientTranslation.length > 0) {
                        translations[t.key] = clientTranslation;
                    }
                });
                return translations;
            }));
        }));
    }

}
