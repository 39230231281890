import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Globals} from '../../globals';
import {Child, Contract, PortalService} from '../../rest';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';


@Component({
    selector: 'app-child-menu',
    templateUrl: './child-menu.component.html',
    styleUrls: ['./child-menu.component.css']
})
export class ChildMenuComponent implements OnInit {

    public showMenu = false;
    public hasActiveContract = false;
    @Input() child: Child = null;
    @Output() linkClicked: EventEmitter<any> = new EventEmitter();

    constructor(private api: PortalService, private router: Router, public globals: Globals) {
    }

    onLinkClick() {
        this.linkClicked.emit(null);
    }

    ngOnInit() {

        this.router.events.subscribe((e: RouterEvent) => {
            if (e instanceof NavigationEnd) {
                // Falls man nicht mehr im Sub-Menü zu diesem Kind ist, wird das Submenü wieder geschlossen
                this.showMenu = e.url.indexOf('child/' + this.child.id) > -1;
            }
        });

        if (this.router.url.indexOf('child/' + this.child.id) > -1) {
            // Falls man im Sub-Menu zu diesem Kind ist, ist es defaultmässig aufgeklappt
            this.showMenu = true;
        }

        this.api.portalChildrenChildidContractsGet(this.child.id).subscribe((contracts: Contract[]) => {
            if (this.globals.additionalsWithoutActiveContract == true) {    // Auch ohne aktiven(!) Vertrag Mehrbelegungen zulassen
                //this.hasActiveContract = contracts.length > 0; // TODO
                this.hasActiveContract = true;
            } else {    // Normalerweise wird gefiltert, ob das Kind bereits aktive verträge besitzt
                this.hasActiveContract = contracts.filter((c: Contract) => c.status == 0).length > 0;
            }
            
        });
    }

    toggleMenu() {
        this.showMenu = !this.showMenu;
    }
}
