import {Component, Input, OnInit} from '@angular/core';
import {Message, PortalService} from '../../rest';
import * as moment from 'moment';
import { saveAs, FileSaver } from 'file-saver';
import { Globals } from '../../globals';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

    @Input() message: Message = null;
    public directionClass = '';
    public timestamp = '';
    public isAttachFetching = false;

    constructor(private api: PortalService, public globals: Globals) {
    }

    ngOnInit() {

        //this.timestamp = moment(this.message.timestamp, 'YYYY-MM-DD HH:mm:ss.S').format('DD.MM.YYYY HH:mm');
        this.timestamp = moment(this.message.timestamp, 'YYYY-MM-DD HH:mm:ss.S').locale(this.globals.language).format('L LTS');

        if (this.message.direction === Message.DirectionEnum.Outbound) {
            this.directionClass = 'outbound';
        } else if (this.message.direction === Message.DirectionEnum.Inbound) {
            this.directionClass = 'inbound';
        }
    }

    requestAttachment() {
        this.isAttachFetching = true;
        const filter = "id%3D%3D" + this.message.id;
        this.api.portalMessagesGet(null, filter, null, null, true).subscribe(
            (msg: Message[]) => {
                // msg[0].file.content comes in format "data;image/png;base64,iVkdsfj303gkserdgsgg[...]"
                const base64data = msg[0].file.content.substring(msg[0].file.content.indexOf(";base64,") + 8);
                const mime =  msg[0].file.content.substring(msg[0].file.content.indexOf(";") + 1, msg[0].file.content.indexOf(";base64,")); 

                const blob = this.base64ToBlob(base64data, mime);
                this.isAttachFetching = false;
                saveAs(blob, msg[0].file.name);
            }
        )
    }
    
    isNumber(val) { return typeof val === 'number'; }

    // Von https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    base64ToBlob(b64Data, contentType='', sliceSize=512) { // Slices in 512 Bytes for performance
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }    
}
