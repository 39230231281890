import {Component, OnInit} from '@angular/core';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-notifications-dialog',
    templateUrl: './notifications-dialog.component.html',
    styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
