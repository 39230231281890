import {Component, OnInit, ViewChild} from '@angular/core';
import {AdditionalOccupancy, Contract, Occupancy, OccupancyType, PortalService} from '../../rest';
import {ErrorHandlerService} from '../../global/error-handler.service';
import {Globals} from '../../globals';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {TableColumn} from '../../global/table/models/table-column';
import {TableComponent} from '../../global/table/table.component';
import {of} from 'rxjs';
import {map, catchError, finalize, filter} from 'rxjs/operators';
import {TableConfiguration} from '../../global/table/models/table-configuration';
import {FormComponent} from '../../global/form/form.component';
import * as field from '../../global/form/models/formfield.index';

@Component({
    selector: 'app-additional-occupancy',
    templateUrl: './additional-occupancy.component.html',
    styleUrls: ['./additional-occupancy.component.css']
})
export class AdditionalOccupancyComponent implements OnInit {

    childId: string = null;
    occupancyId: string = null;
    fields: any[];
    data = null;

    @ViewChild(FormComponent, { static: true }) private form: FormComponent;

    constructor(private api: PortalService, private errorHandler: ErrorHandlerService,
                private route: ActivatedRoute, public globals: Globals) {
    }

    ngOnInit() {
        this.form.clear();
        this.globals.globalTitle = 'Zusatztag';
        this.route.paramMap.subscribe(
            (p: ParamMap) => {
                this.childId = p.get('childId');
                this.globals.setBackUrl('/child/' + this.childId + '/additionalOccupancies');
                this.occupancyId = p.get('occupancyId');
                this.loadForm();
            }
        );
    }

    loadForm() {
        this.form.clear();
        if (this.occupancyId === 'new') {
            this.data = {};
            this.fields = this.formIni(this.data);

            this.form.setData(this.data);
            this.form.setFields(this.fields);
        } else {
            this.api.portalChildrenChildidAdditionalOccupancyGet(this.childId).subscribe(
                occupancies => {
                    this.data = occupancies.filter((o: AdditionalOccupancy) => o.id === this.occupancyId)[0];
                    this.fields = this.formIni(this.data);

                    this.form.setData(this.data);
                    this.form.setFields(this.fields);

                },
                error => {
                    this.errorHandler.handle(error);
                }
            );
        }
    }

    formIni(data: Contract): field.FormfieldBase<any>[] {

        const occupancyTypes = this.globals.occupancyTypes.pipe(map((types: OccupancyType[]) => {
            return types ? [{key: null, value: '-'}]
                .concat(
                    types
                    .filter((type: OccupancyType) => {
                        return (type.status&8)==8;
                    })
                    .map((type: OccupancyType) => {
                        return {key: type.id, value: type.name};
                    })
                ) : [];
        }));

        const disabled = this.occupancyId !== 'new' && data.status < 20;

        return [
            new field.Date({key: 'addDate', label: 'Tag', required: true, disabled: disabled}),
            new field.Select({key: 'occupancyTypeId', label: 'Belegung', values: occupancyTypes, required: true, disabled: disabled}),
            new field.Textarea({key: 'remarks', label: 'Bemerkungen', disabled: disabled})
        ];
    }

    saveFnc(data: AdditionalOccupancy) {
        data = {...this.data, ...data};
        data.childId = this.childId;

        const serviceCall = this.occupancyId === 'new' ?
            this.api.portalChildrenChildidAdditionalOccupancyPost(this.childId, data) :
            this.api.portalChildrenChildidAdditionalOccupancyOccupancyidPut(this.childId, this.occupancyId, data);

        // nachdem der Service aufgerufen wurde, soll auf die übersichtsliste der Beiträge navigiert werden
        return serviceCall.pipe(
            finalize(() => this.globals.back())
        );
    }
}
