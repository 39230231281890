import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Globals } from "../globals";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private globals: Globals) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError(
                (err: HttpErrorResponse) => {
                    if (err.status === 401 || err.status === 400) {

                        // Clear tokens from local Storage
                        AuthService.clearStorage();
                        
                        // Set global variable loggedIn to false (so the menu etc. hides)
                        this.globals.loggedIn = false;

                        // Redirect to login page
                        this.router.navigate(['/login']);
                    }
                    return throwError(err);
                }
            )
        );
    }
}