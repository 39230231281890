<div class="content-centered">
    <mat-card *ngIf="data" class="info-card">
        <mat-card-content>
            <p *ngIf="childId!='new'" [innerHTML]="'Hier können Sie die Grunddaten Ihres Kindes bearbeiten.' | translate"></p>
            <p *ngIf="childId=='new'" [innerHTML]="'Hier können Sie ein neues Kind erfassen.' | translate"></p>
        </mat-card-content>
    </mat-card>
    <app-form [fields]="fields" [data]="data" [saveFnc]="this.saveFnc.bind(this)"></app-form>

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('children_footer' | translate) !== 'children_footer'">
        <mat-card-content>
            <p [innerHTML]="'children_footer' | translate"></p>
        </mat-card-content>
    </mat-card>

</div>