import {Component, OnInit, ViewChild} from '@angular/core';
import {ActionResponse, AdditionalOccupancy, PortalService} from '../../rest';
import {ErrorHandlerService} from '../../global/error-handler.service';
import {Globals} from '../../globals';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TableColumn} from '../../global/table/models/table-column';
import {TableComponent} from '../../global/table/table.component';
import {of, forkJoin} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {TableConfiguration} from '../../global/table/models/table-configuration';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-additional-occupancies-list',
  templateUrl: './additional-occupancies-list.component.html',
  styleUrls: ['./additional-occupancies-list.component.scss']
})
export class AdditionalOccupanciesListComponent implements OnInit {

    childId: string = null;

    @ViewChild(TableComponent, { static: true }) table: TableComponent;

    constructor(private api: PortalService, private errorHandler: ErrorHandlerService,
                private route: ActivatedRoute, public globals: Globals, private router: Router) {
    }

    ngOnInit() {
        this.globals.globalTitle = 'Belegung';
        this.route.paramMap.subscribe(
            (p: ParamMap) => {
                this.childId = p.get('childId');
                this.tableIni();
            }
        );
    }


    getData(filter?, sort?, page?, perPage?) {
        return this.api
            .portalChildrenChildidAdditionalOccupancyGet(this.childId)
            .pipe(
                map((occupancies: AdditionalOccupancy[]): {
                    id: string,
                    date: string,
                    type: string,
                    from: string,
                    until: string,
                    status: string
                }[] => {
                    // Daten werden umstrukturiert und sortiert
                    return occupancies
                        .map(o => {
                            return {
                                id: o.id,
                                date: o.addDate,
                                type: o.occupancyType.name,
                                from: o.occupancyType.timeFrom,
                                until: o.occupancyType.timeUntil,
                                status: new Map([
                                  [0, 'bewilligt'],
                                  [20, 'bearbeitbar'] // 20 = Angefragt
                                ]).get(o.status)
                            };
                        })
                        .sort((o1, o2) => {
                            return o1.date < o2.date ? -1 : 1;
                        });
                }),
                catchError(() => {
                    this.globals.error = 'Fehler beim laden der Daten.';
                    return of([]);
                })
            );
    }

    tableIni() {
        this.table.setConfiguration(new TableConfiguration({
            title: 'Zusatztage',
            clientProcessing: true
        }));
        this.table.setColumns([
            new TableColumn({field: 'date', title: 'Tag', type: 'datesql', width: 33, sortable: true, sortDirection: 'asc'}),
            new TableColumn({field: 'type', title: 'Typ', type: 'text', width: 33, sortable: true}),
            new TableColumn({field: 'from', title: 'Von', type: 'time', width: 12, sortable: true}),
            new TableColumn({field: 'until', title: 'Bis', type: 'time', width: 12, sortable: true}),
            new TableColumn({field: 'status', title: 'Status', type: 'text', width: 12, sortable: true})
        ]);
        this.table.setDataSourceService(this.getData.bind(this));
    }

    loadOccupancy(row) {
        this.router.navigateByUrl('child/' + this.childId + '/additionalOccupancy/' + row.id);
    }

    newOccupancy() {
        this.router.navigateByUrl('child/' + this.childId + '/additionalOccupancy/new');
    }

    deleteOccupancies(rows: any[]) {
        const promises = {};

        // Speichern aller promises
        rows.forEach(occupancy => {
            promises[occupancy.id] = this.api.portalChildrenChildidAdditionalOccupancyOccupancyidDelete(this.childId, occupancy.id);
        });

        // 1x alle promises ausführen, damit wir am Ende 1x ein refresh der Tabelle (binding) ausführen können
        forkJoin(promises).subscribe(
          (res: any) => {
            let errors = 0;

            Object.keys(res).forEach((key, index) => {
              if (res[key].status !== '0') {
                errors++;
              }
            });

            if (errors > 0) {
              this.table.message('Eine oder mehrere Zusatztage-Anfrage(n) konnte(n) nicht gelöscht werden.', 'OK', true);
            } else {
              this.table.message('Die Zusatztag-Anfrage(n) wurde(n) gelöscht.', 'OK', true);
            }
          },
          error => {
              this.globals.debug(error);
              this.table.message('Es ist ein unbekannter Fehler aufgetreten.', 'OK', true);
          },
          () => {
            this.table.reloadData();
          }
        );

    }
}
