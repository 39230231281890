import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { PortalService } from '../../rest';
import { Meal } from '../../rest/model/meal';

@Component({
  selector: 'app-meals',
  templateUrl: './meals.component.html',
  styleUrls: ['./meals.component.scss']
})
export class MealsComponent implements OnInit {

  public meals: Meal[] = [];
  public loading = false;
  
  constructor(public globals: Globals, private api: PortalService) {
    this.globals.globalTitle = 'Menüplan';
  }

  ngOnInit(): void {
    this.loadMeals();
  }

  loadMeals() {
      this.loading = true;
      
      const filter = "date";
      this.api.portalMenuScheduleGet(filter).subscribe(
          (m: Meal[]) => {
              this.meals = m;

              this.loading = false;
          },
          error => {
              this.loading = false;
          }
      );
  }

}
