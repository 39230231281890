<div class="content-centered">

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('additional_occupancy_header' | translate) !== 'additional_occupancy_header'">
        <mat-card-content>
            <p [innerHTML]="'additional_occupancy_header' | translate"></p>
        </mat-card-content>
    </mat-card>

    <app-form [saveFnc]="this.saveFnc.bind(this)"></app-form>

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('additional_occupancy_footer' | translate) !== 'additional_occupancy_footer'">
        <mat-card-content>
            <p [innerHTML]="'additional_occupancy_footer' | translate"></p>
        </mat-card-content>
    </mat-card>
    
</div>