<h1 mat-dialog-title>{{ 'Neue Nachricht' | translate }}</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <input matInput type="text" placeholder="{{ 'Betreff *' | translate }}" [(ngModel)]="data.subject" [formControl]="subjectField">
        <mat-error *ngIf="subjectField.invalid">{{ 'Geben Sie einen Betreff an.' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <textarea rows="5" matInput placeholder="{{ 'Nachricht *' }}" [(ngModel)]="data.content" [formControl]="contentField"></textarea>
        <mat-error *ngIf="contentField.invalid">{{ 'Geben Sie eine Nachricht an.' | translate }}</mat-error>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="send()" cdkFocusInitial [disabled]="subjectField.invalid || contentField.invalid">{{ 'Senden' | translate }}</button>
    <button mat-button [mat-dialog-close]="null">{{ 'Abbrechen' | translate }}</button>
</div>