<mat-card class="info-card table-card">
    <!--<button mat-mini-fab color="primary" class="plus-button-top-right" (click)="newClicked.emit(null)">-->
        <!--<fa-icon [icon]="['far', 'plus']" size="lg"></fa-icon>-->
    <!--</button>-->
    <button mat-mini-fab color="primary" class="plus-button-bottom-right"
            *ngIf="newClickable" (click)="newClicked.emit(null)">
        <fa-icon [icon]="['far', 'plus']" size="lg"></fa-icon>
    </button>
    <mat-card-header *ngIf="configuration && configuration.title">
        <mat-card-title>
            {{ configuration.title | translate }}
        </mat-card-title>
        <span class="space-filler"></span>
        <button mat-icon-button class="icon-button-lg" color="primary" (click)="delClicked.emit(selection.selected)"
                [disabled]="!selection.hasValue()" *ngIf="delClickable">
            <fa-icon [icon]="['far', 'trash-alt']" size="lg"></fa-icon>
        </button>
    </mat-card-header>
    <mat-card-content>
        <mat-table [dataSource]="configuration.clientProcessing ? dataSourceClient : dataSourceServer" *ngIf="displayedColumns.length > 0" matSort [matSortActive]="sortActive" [matSortDirection]="sortDirection" matSortDisableClear>

            <!-- Columns -->

            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef (click)="$event.stopPropagation()" fxFlex="64px">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                                  [color]="'primary'">
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" fxFlex="64px">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null"
                                  [checked]="selection.isSelected(row)"
                                  [color]="'primary'">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container *ngFor="let col of columns" matColumnDef="{{col.field}}">
                <ng-container *ngIf="col.sortable">
                    <mat-header-cell *matHeaderCellDef  [ngStyle]="colStyles[col.field]" mat-sort-header>
                        {{col.title | translate }}
                    </mat-header-cell>
                </ng-container>
                <ng-container *ngIf="!col.sortable">
                    <mat-header-cell *matHeaderCellDef  [ngStyle]="colStyles[col.field]">
                        {{col.title | translate }}
                    </mat-header-cell>
                </ng-container>
                <mat-cell *matCellDef="let element" [ngStyle]="colStyles[col.field]" class="cell-align-{{col.align}}">
                    <ng-container [ngSwitch]="col.type">
                        <ng-container *ngSwitchCase="'datesql'">
                            {{ element[col.field] | date:'dd.MM.yyyy' }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'time'">
                            {{ element[col.field] | slice:0:5 }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{ element[col.field] }}
                        </ng-container>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Header with displayed Rows -->
            <!--<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>-->

            <ng-container *matHeaderRowDef="displayedColumns">
                <mat-header-row></mat-header-row>
                <mat-progress-bar *ngIf="loading | async" mode="indeterminate"></mat-progress-bar>
            </ng-container>

            <!-- Table Rows -->
            <mat-row *matRowDef="let row; columns: displayedColumns;"
                     (click)="rowClicked.emit(row)" [ngClass]="{'table-row-hover': !row.noEdit, 'table-row-no-edit': row.noEdit}">
            </mat-row>
        </mat-table>

        <div *ngIf="((configuration.clientProcessing ? dataSourceClient.connect() : dataSourceServer.connect(null))|async).length === 0" class="table-empty">
            <ng-container *ngIf="(loading | async); else empty">
                {{ 'Daten werden geladen' | translate }}
            </ng-container>
            <ng-template #empty>
                {{ 'Keine Daten vorhanden' | translate }}
            </ng-template>
        </div>
    </mat-card-content>
</mat-card>