import {Component, OnInit, ViewChild} from '@angular/core';
import * as field from '../../global/form/models/formfield.index';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Globals} from '../../globals';
import {FormComponent} from '../../global/form/form.component';
import {ErrorHandlerService} from '../../global/error-handler.service';
import {ActionResponse, Child, PortalService, Kindergarten} from '../../rest';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';
import {AuthGuardService} from '../../routing/auth-guard.service';

@Component({
    selector: 'app-children',
    templateUrl: './children.component.html',
    styleUrls: ['./children.component.css']
})
export class ChildrenComponent implements OnInit {

    childId: string = null;
    fields: any[];
    data = null;
    status: number = null;

    @ViewChild(FormComponent, { static: true }) private form: FormComponent;

    constructor(private api: PortalService, private errorHandler: ErrorHandlerService, private router: Router,
                private route: ActivatedRoute, public globals: Globals) {
    }


    ngOnInit(): void {
        this.form.clear();
        this.globals.globalTitle = 'Grunddaten Kind';
        this.route.paramMap.subscribe(
            (p: ParamMap) => {
                this.childId = p.get('childId');
                this.loadForm();
            }
        );
    }

    saveFnc(data: Child) {
        data = {...this.data, ...data};
        data.status = this.status;
        if (this.childId === 'new') {
            return this.api.portalChildrenPost(data).pipe(
                map((res: ActionResponse) => {
                    if (res.status === '0') {
                        this.api.portalChildrenGet().subscribe((kids: Child[]) => {
                            this.globals.childrenForMenu.next(kids);
                            this.router.navigateByUrl('child/' + res.id + '/contract/new?validFrom=' + data.desiredEntryDate);
                        });
                    }
                    return res;
                })
            );
        } else {
            return this.api.portalChildrenChildidPut(this.childId, data).pipe(
                map((res: ActionResponse) => {
                    if (res.status === '0') {
                        this.api.portalChildrenGet().subscribe((kids: Child[]) => {
                            this.globals.childrenForMenu.next(kids);
                        });
                    }
                    return res;
                })
            );
        }
    }

    loadForm() {
        this.form.clear();
        if (this.childId === 'new') {
            this.data = {};
            this.fields = this.formIni(this.data);
            this.status = 20;

            this.form.setData(this.data);
            this.form.setFields(this.fields);
        } else {
            this.api.portalChildrenChildidGet(this.childId).subscribe(
                c => {
                    this.data = c;
                    this.fields = this.formIni(this.data);
                    this.status = c.status;

                    this.form.setData(this.data);
                    this.form.setFields(this.fields);

                },
                error => {
                    this.errorHandler.handle(error);
                }
            );
        }
    }

    formIni(data: Child): field.FormfieldBase<any>[] {

        const kindergartens = this.globals.kindergartens.pipe(map((schools: Kindergarten[]) => {
            return schools ? [{key: null, value: '-'}]
                .concat(schools.map((g: Kindergarten) => {
                    return {key: g.id, value: g.name};
                })) : [];
        }));

        let fields = [];
        if ((!data.existing || this.childId === 'new') && (this.globals.childFullForm == false)) {
            // vereinfachte Maske, falls Kind noch nicht definitiv aufgenommen ist
            fields = [
                new field.Text({key: 'firstName', label: 'Vorname', required: true, nobreak: true}),
                new field.Text({key: 'lastName', label: 'Nachname', required: true, nobreak: true}),
                new field.Date({key: 'birthday', label: 'Geburtsdatum', required: true}),

                new field.Date({key: 'desiredEntryDate', label: 'Gewünschtes Eintrittsdatum', required: true, nobreak: true}),

                new field.Select({
                    key: 'gender', label: 'Geschlecht', values: [
                        {key: 'male', value: 'Männlich'},
                        {key: 'female', value: 'Weiblich'},
                        {key: 'unknown', value: 'Unbekannt'}
                    ], required: true
                })
            ];
        } else {
            fields = [
                new field.Text({key: 'firstName', label: 'Vorname', required: true, nobreak: true}),
                new field.Text({key: 'lastName', label: 'Nachname', required: true, nobreak: true}),
                new field.Date({key: 'birthday', label: 'Geburtsdatum', required: true}),

                new field.Date({key: 'desiredEntryDate', label: 'Gewünschtes Eintrittsdatum', required: true, nobreak: true}),

                new field.Select({
                    key: 'gender', label: 'Geschlecht', values: [
                        {key: 'male', value: 'Männlich'},
                        {key: 'female', value: 'Weiblich'},
                        {key: 'unknown', value: 'Unbekannt'}
                    ], required: true
                }),
                new field.Text({key: 'nationality', label: 'Nationalität', nobreak: true}),

                new field.Select({
                    key: 'childCustody', label: 'Sorgerecht', values: [
                        {key: 'eltern', value: 'Eltern'},
                        {key: 'mutter', value: 'Mutter'},
                        {key: 'vater', value: 'Vater'},
                        {key: 'andere', value: 'Andere'}
                    ], required: true
                }),
                new field.Text({key: 'pickupPerson', label: 'Abholung durch (Name(n))', nobreak: true}),


                new field.Select({
                    key: 'foodmenu',
                    label: 'Essens-Typ',
                    values: this.globals.foodMenues,
                    required: true,
                    value: data.foodmenu ? data.foodmenu.key : null,
                    returnKeyVal: true
                }),
                new field.Multiselect({
                    key: 'foodallergies',
                    label: 'Lebensmittel-Allergien',
                    values: this.globals.foodAllergies,
                    returnKeyVal: true,
                    value: data.foodallergies ? data.foodallergies.map(x => x.key) : null,
                    colspan: 2
                }),
                new field.Multiselect({
                    key: 'vaccines',
                    label: 'Impfungen',
                    values: this.globals.vaccines,
                    returnKeyVal: true,
                    value: data.vaccines ? data.vaccines.map(x => x.key) : null,
                    colspan: 2
                }),

                new field.Colheader({key: 'emergency1', label: 'Notfallkontakt 1'}),
                new field.Text({key: 'emergencyContact1', label: 'Name'}),
                new field.Text({key: 'emergencyPhone1', label: 'Telefon 1', nobreak: true}),
                new field.Text({key: 'emergencyPhoneBusiness1', label: 'Tel. Geschäft 1', nobreak: true}),

                new field.Colheader({key: 'emergency2', label: 'Notfallkontakt 2'}),
                new field.Text({key: 'emergencyContact2', label: 'Name'}),
                new field.Text({key: 'emergencyPhone2', label: 'Telefon 2', nobreak: true}),
                new field.Text({key: 'emergencyPhoneBusiness2', label: 'Tel. Geschäft 2', nobreak: true}),

                new field.Colheader({key: 'emergency3', label: 'Notfallkontakt 3'}),
                new field.Text({key: 'emergencyContact3', label: 'Name'}),
                new field.Text({key: 'emergencyPhone3', label: 'Telefon 3', nobreak: true}),
                new field.Text({key: 'emergencyPhoneBusiness3', label: 'Tel. Geschäft 3', nobreak: true}),

                new field.Colheader({key: 'schools', label: 'Schule / Kindergarten'}),
                new field.Select({
                    key: 'kindergartenId',
                    label: 'Schule / Kindergarten',
                    values: kindergartens,
                    value: data.kindergartenId ? data.kindergartenId : null,
                    //returnKeyVal: true
                }),
                new field.Text({key: 'teacherClass', label: 'Klasse', nobreak: true}),
                new field.Text({key: 'teacherName', label: 'Lehrperson Name'}),
                new field.Text({key: 'teacherPhone', label: 'Lehrperson Telefon', nobreak: true}),

                new field.Colheader({key: 'versicherungen', label: 'Versicherungen'}),
                new field.Text({key: 'healthInsurance', label: 'Krankenkasse', required: true}),
                new field.Text({key: 'accidentInsurance', label: 'Unfallversicherung', required: true, nobreak: true}),
                new field.Text({key: 'liabilityInsurance', label: 'Haftpflicht', required: true, nobreak: true}),

                new field.Colheader({label: 'Bemerkungen', key: 'remarksgroup'}),
                new field.Textarea({key: 'remarks', label: 'Bemerkungen'}),
            ];
        }

        return fields;
    }

}
