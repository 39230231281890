<mat-sidenav-container class="fullscreen">
    <mat-sidenav mode="{{sidenav_mode}}" #sidenav class="sidenav padding-safe-area">
        <mat-toolbar color="accent">
            <div style="text-align: center;  margin-left: auto; margin-right: auto;">
			    <span style="display: inline-block; height: 100%; vertical-align: middle;"></span>
				<img alt="{{ 'Eltern-Portal' | translate }}" src="{{globals.logoUrl}}" style="max-width: 217px; max-height: 64px; vertical-align: middle;" (error)="imgError($event)">
            </div>
        </mat-toolbar>
        <mat-nav-list *ngIf="this.globals.loggedIn" class="side-list">
            <a mat-list-item routerLink="/start" routerLinkActive="route-active" (click)="collapse()">
                <fa-icon [icon]="['fas', 'home']" class="menu-icon"></fa-icon>
                {{ 'Start' | translate }}
            </a>
            <a mat-list-item routerLink="/parents" routerLinkActive="route-active" (click)="collapse()">
                <fa-icon [icon]="['fas', 'user-friends']" class="menu-icon"></fa-icon>
                {{ 'Eltern' | translate }}
            </a>
        </mat-nav-list>
        <div *ngIf="this.globals.loggedIn">
            <app-child-menu *ngFor="let kid of globals.childrenForMenu | async"
                        [child]="kid" (linkClicked)="collapse()"></app-child-menu>
        </div>
        <mat-nav-list *ngIf="this.globals.loggedIn" class="side-list">
            <a mat-list-item routerLink="/child/new" routerLinkActive="route-active" (click)="collapse()">
                <fa-icon [icon]="['fas', 'user-plus']" class="menu-icon"></fa-icon>
                {{ 'Zusätzliches Kind anmelden' | translate }}
            </a>
        </mat-nav-list>
        <mat-nav-list *ngIf="this.globals.loggedIn && pageVisible('meals')" class="side-list">
            <a mat-list-item routerLink="/meals" routerLinkActive="route-active" (click)="collapse()">
                <fa-icon [icon]="['fas', 'utensils']" class="menu-icon"></fa-icon>
                {{ 'Menüplan' | translate }}
            </a>
        </mat-nav-list>
        <mat-nav-list *ngIf="this.globals.loggedIn && pageVisible('appointments')" class="side-list">
            <a mat-list-item routerLink="/appointments" routerLinkActive="route-active" (click)="collapse()">
                <fa-icon [icon]="['fas', 'calendar-alt']" class="menu-icon"></fa-icon>
                {{ 'Termine' | translate }}
            </a>
        </mat-nav-list>
        <mat-nav-list *ngIf="this.globals.loggedIn" class="side-list">
            <a mat-list-item routerLink="/settings" routerLinkActive="route-active" (click)="collapse()">
                <fa-icon [icon]="['fas', 'cog']" class="menu-icon"></fa-icon>
                {{ 'Einstellungen' | translate }}
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary" class="padding-safe-area">
            <button mat-icon-button class="icon-button-lg" (click)="sidenav.toggle()" *ngIf="sidenav_mode == 'over' && this.globals.loggedIn == true && this.globals.backURL == null">
                <fa-icon [icon]="['far', 'bars']" size="lg"></fa-icon>
            </button>
            <button mat-icon-button class="icon-button-lg" (click)="globals.back()" *ngIf="globals.backURL != null">
                <fa-icon [icon]="['far', 'arrow-left']" size="lg"></fa-icon>
            </button>
            <span>
                {{ globals.globalTitle | translate}}
            </span>
            <span class="example-fill-remaining-space"></span>
            <button mat-icon-button class="icon-button-2x" *ngIf="this.globals.loggedIn == true" routerLink="/messages"
                    matBadge="{{ globals.countUnreadMessages | async }}" matBadgeColor="warn" [matBadgeHidden]="(globals.countUnreadMessages | async) == 0">
                <fa-icon [icon]="['fas', 'envelope']" size="2x"></fa-icon>
            </button>
            <button mat-icon-button class="icon-button-2x" (click)="logOut()" *ngIf="this.globals.loggedIn == true">
                <fa-icon [icon]="['fas', 'sign-out']" size="2x"></fa-icon>
            </button>
        </mat-toolbar>

        <div class="inline-scroll padding-safe-area" [ngClass]="{'inline-scroll-collapsed': sidenav_mode === 'over'}" #scroller>
            <mat-card *ngIf="this.globals.error" class="error-info info-card">
                <mat-card-content [innerHTML]="this.globals.error | translate">
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button (click)="closeWarning()">{{ 'Schliessen' | translate }}</button>
                </mat-card-actions>
            </mat-card>
            <mat-card class="info-card" *ngIf="this.globals.loadingInfo">
                <mat-progress-bar class="info-card-loading" mode="indeterminate"></mat-progress-bar>
                <mat-card-content [innerHTML]="this.globals.loadingInfo">
                </mat-card-content>
            </mat-card>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
