import {Component, Input, OnInit} from '@angular/core';
import {FormfieldBase} from './models/formfield-base';
import {FormGroup,Validators} from '@angular/forms';
import {FormfieldControlService} from './models/formfield-control.service';
import {ActionResponse} from '../../rest/model/actionResponse';
import {Observable} from 'rxjs/Observable';
import {Globals} from '../../globals';
import {ErrorHandlerService} from '../error-handler.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as field from './models/formfield.index';
import {SelectFormfield} from './models/formfield-select';
import {Setting} from '../../rest';
import {MultiselectFormfield} from './models/formfield-multiselect';
import {environment} from '../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import { FormFieldDynamicPairs, FormFieldPair } from './models/formfield-dynamic-pairs';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

    @Input() fields: FormfieldBase<any>[] = [];
    @Input() data = [];
    @Input() saveFnc: (data) => Observable<ActionResponse> = null;
    @Input() maxWidth = 'auto';

    form: FormGroup;
    groups: FormfieldBase<any>[][][] = [];
    groupKeys = {};
    fieldKeys = {};

    constructor(private fcs: FormfieldControlService, private errorHandler: ErrorHandlerService,
                public globals: Globals, public snackBar: MatSnackBar, private translate: TranslateService) {}

    private static createField(f): FormfieldBase<any> {
        let formfield = new FormfieldBase();
        const options = {key: f.key, label: f.label, required: f.required || false};
        switch (f['type']) {
            case 'text':
                formfield = new field.Text(options);
                break;
            case 'select':
                formfield = new field.Select(options);
                break;
            case 'date':
                formfield = new field.Date(options);
                break;
            case 'email':
                formfield = new field.Email(options);
                break;
        }

        return formfield;
    }

    ngOnInit() {
    }

    public clear() {
        this.fields = [];
        this.groups = [];
        this.groupKeys = {};
        this.fieldKeys = {};
        this.form = null;
    }

    setFields(fields) {
        this.clear();
        this.addFields(fields);
    }

    public setData(data) {
        this.data = data;
    }

    private addGroup(group: FormfieldBase<any>[][], maxCols: number, groupKey: string) {

        groupKey = groupKey || 'defaultgroup' + this.groups.length;

        group.forEach((r: FormfieldBase<any>[], rowIdx) => {
            let countInThisRow = 0;
            r.forEach((f: FormfieldBase<any>, colIdx) => {
                this.fieldKeys[f.key] = {
                    group: this.groups.length,
                    row: rowIdx,
                    col: colIdx
                };

                if (f.controlType === 'colheader') {
                    f.colspan = maxCols;
                }

                f.fxMaxWidth = 'calc(' + (f.colspan * (100 / maxCols)) + '%)';

                countInThisRow += f.colspan;
            });
            const missingCols = maxCols - countInThisRow;
            for (let i = 0; i < missingCols; i++) {
                r.push(new field.Empty());
            }
        });

        this.groupKeys[groupKey] = this.groups.length;

        this.groups.push(group);
    }

    public addFields(fields: FormfieldBase<any>[]) {

        let row = [];
        let group = [];
        let groupKey = 'defaultgroup' + this.groups.length;
        let maxCols = 0;
        fields.forEach((f: FormfieldBase<any>) => {

            this.fields.push(f);

            if(f.controlType === 'dynamicPairs') {
                // Bei dynamischen Feld-Paaren müssen die Labels beim dynamischen hinzufügen auch übersetzt werden,
                // deswegen wird der Translation-Service dem dyncamicPair übergeben
                f.setTranslationService(this.translate);
            } else if(f.label) {
                // Label nur übersetzen, falls auch eins gesetzt ist
                f.label = this.translate.instant(f.label);
            }

            if (f.required && f.label.length > 0) {
                f.label += ' *';
            }

            if (f.nobreak && f.controlType !== 'colheader') {
                // Falls dieses Feld auf der fleichen Zeile wie die vorherigen ausgegeben werden soll,
                // und es sich nicht um ein Colheader handelt (diese werden immer in einer neuen Zeile
                // angezeigt), wird das Feld der aktuellen Zeile hinzugefügt
                row.push(f);
            } else {
                // Sonst wird das Feld in einer Zeile angezeigt
                if (row.length > 0) {
                    // Falls die aktuelle Zeile Felder enthält, wird diese der aktuellen Gruppe hinzugefüt
                    // und die Anzahl Felder pro Zeile wird für's Layout zwischengespeichert
                    group.push(row);
                    if (row.length > maxCols) {
                        maxCols = row.length;
                    }
                }

                if (f.controlType === 'colheader') {
                    // Falls es sich bei diesem Feld um ein Colheader handelt, und die aktuelle Form-Gruppe
                    // schon Zeilen enthält, wird diese den Form-Gruppen angehängt
                    if (group.length > 0) {
                        this.addGroup(group, maxCols, groupKey);
                    }

                    // Ab jetzt kommen die Zeilen in eine neue Gruppe
                    group = [];
                    maxCols = 0;
                    groupKey = f.key;
                }
                //  Ab jetzt kommen die Felder in eine neue Zeile
                row = [];
                row.push(f);
            }
        });

        if (row.length > 0) {
            if (row.length > maxCols) {
                maxCols = row.length;
            }
            group.push(row);
        }

        if (group.length > 0) {
            this.addGroup(group, maxCols, groupKey);
        }

        this.form = FormfieldControlService.toFormGroup(this.fields, this.data);

        fields.forEach(f => {
            if (f.onchange && f.controlType !== 'button') {
                this.form.get(f.key).valueChanges.subscribe(val => {
                    f.onchange(val);
                });
            }
        });
    }

    public getField(key: string): FormfieldBase<any> {
        const idx = this.fieldKeys[key];
        return this.groups[idx.group][idx.row][idx.col];
    }

    public hideField(key: string, hidden: boolean) {
        const f = this.getField(key);
        f.hidden = hidden;
    }

    public hideRow(group: string, row: number, hidden: boolean) {
        const groupIdx = this.groupKeys[group];
        this.groups[groupIdx][row].forEach((f: FormfieldBase<any>) => {
            f.hidden = hidden;
        });
    }

    public hideGroup(group: string, hidden: boolean) {
        const groupIdx = this.groupKeys[group];
        this.groups[groupIdx].forEach((row: FormfieldBase<any>[]) => {
            row.forEach((f: FormfieldBase<any>) => {
                f.hidden = hidden;
            });
        });
    }

    public setFieldRequired(key: string, required: boolean) {
        const field = this.getField(key);
        
        if (field.required && field.label.length > 2) {
            let labelOld = field.label;
            field.label = field.label.substr(0, (field.label.length-2));
        }

        // required anpassen
        field.required = required;
        // validatoren müssen neu aufgebaut werden
        const validators = [];
        if (field.required) {
            if (field.label.length > 0) {
                field.label += ' *';
            }
            if (field.controlType === 'checkbox') {
                validators.push(Validators.requiredTrue);
            } else {
                validators.push(Validators.required);
            }
        }
        if (field.controlType === 'email') {
            validators.push(Validators.email);
        }
        this.form.get(key).setValidators(validators);
        this.form.get(key).updateValueAndValidity();
    }

    getFormValues() {
        const formValues = this.form.value;

        this.groups.forEach(rows => {
            rows.forEach(row => {
                row.forEach(f => {
                    const v = formValues[f.key];

                    if (f.controlType === 'date' && !f.disabled && v.isValid && v.isValid()) {
                        formValues[f.key] = v.format('YYYY-MM-DD');
                    }

                    if (f instanceof SelectFormfield && f.returnKeyVal) {
                        formValues[f.key] = f.values.getValue().find(s => {
                            return s.key === v;
                        });
                    }

                    if (f instanceof MultiselectFormfield && f.returnKeyVal) {
                        formValues[f.key] = [];
                        if (v instanceof Array) {
                            const values = f.values.getValue();
                            v.forEach(val => {
                                formValues[f.key].push(values.find(s => {
                                    return s.key === val;
                                }));
                            });
                        }
                    }

                    if (f instanceof FormFieldDynamicPairs) {
                        formValues[f.key] = [];
                        f.pairs.forEach(pair => {
                            const pairRow = [];
                            pair.getFields().forEach(pairField => {

                                let pairVal = formValues[pairField.key];
                                if (pairVal instanceof FormGroup) {
                                    // durch das dymansiche hinzufügen kann es sein dass die FormGroup des ganzen Formulars einzelne FormGroups enthält
                                    pairVal = pairVal.value[pairField.key];
                                }

                                pairRow.push(pairVal);
                                delete formValues[pairField.key];
                            });
                            formValues[f.key].push(pairRow);
                        });
                    }
                });
            });
        });

        return formValues;
    }

    onSubmit() {

        // Daten aus dem Formular ermitteln
        const values = this.getFormValues();

        if (this.saveFnc) {
            this.form.markAsPristine();
            this.saveFnc(values).subscribe(
                (res: ActionResponse) => {
                    if (res.status === '0') {
                        this.message('Die Angaben wurden erfolgreich gespeichert', 'OK');
                    } else {
                        this.message('Die Angaben konnten nicht gespeichert werden: ' + res.message, 'OK', false);
                        this.form.markAsDirty();
                    }
                },
                error => {
                    this.form.markAsDirty();
                    this.globals.debug('Fehler beim speichern' + error);
                    let msg = 'Die Angaben konnten nicht gespeichert werden: ';
                    msg += (error instanceof HttpErrorResponse) ? error.statusText + ' (' + error.status + ')' : 'Unbekannter Fehler.';
                    this.message(msg, 'OK', false);
                }
            );
        } else if (!environment.production) {
            this.globals.debug('would save data, but no callback given. Values: ' + JSON.stringify(values));
            this.form.markAsPristine();
        }
    }

    message(message: string, action: string, autoClose: boolean = true) {
        const config = {};
        if (autoClose) {
            config['duration'] = 5000;
        }

        this.snackBar.open(message, action, config);
    }
}
