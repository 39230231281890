import { Component, OnInit, Input } from '@angular/core';
import { Meal } from 'src/app/rest/model/meal';
import * as moment from 'moment';
import { Globals } from '../../globals';

@Component({
  selector: 'app-meal',
  templateUrl: './meal.component.html',
  styleUrls: ['./meal.component.scss']
})
export class MealComponent implements OnInit {

  @Input() meal: Meal = null;
  public date = '';
  public weekdayShort = '';
  constructor(public globals: Globals) { }

  ngOnInit(): void {
    this.date = moment(this.meal.date, 'YYYY-MM-DD').locale(this.globals.language).format('ll');
    this.weekdayShort =  moment(this.meal.date, 'YYYY-MM-DD').locale(this.globals.language).format('ddd');
  }

}
