import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

export class TableDataSource implements DataSource<any> {

    public filter = false;
    public pagination = false;
    public sort = false;

    private rows = new BehaviorSubject<any[]>([]);
    private loading = new BehaviorSubject<boolean>(false);

    public data: any[] = [];

    private service: (filter?, sort?, page?, perPage?) => Observable<any[]> = null;

    public loading$ = this.loading.asObservable();

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.rows.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.rows.complete();
        this.loading.complete();
    }

    public loadData(filter?, sort?, page?, perPage?) {
        this.loading.next(true);

        this.service(filter, sort, page, perPage).pipe(
            catchError(() => of([])),
            finalize(() => this.loading.next(false))
        ).subscribe(data => {
            this.data = data;
            this.rows.next(data);
        });
    }

    public setService(service: (filter?, sort?, page?, perPage?) => Observable<any[]>) {
        this.service = service;
    }
}
