<div class="content-centered">

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('meals_header' | translate) !== 'meals_header'">
        <mat-card-content>
            <p [innerHTML]="'meals_header' | translate"></p>
        </mat-card-content>
    </mat-card>

    <mat-card *ngIf="meals" class="info-card" id="card">
        <mat-progress-bar class="info-card-loading" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        <mat-card-content>
            <div id="mealsWrapper">
                <app-meal *ngFor="let m of meals" [meal]="m"></app-meal>
            </div>
            <div *ngIf="meals.length === 0">
                <p *ngIf="loading">{{ 'Die Menüpläne werden geladen' | translate }}.</p>
                <p *ngIf="!loading">{{ 'Es sind keine Menüpläne vorhanden.' | translate }}</p>
            </div>
        </mat-card-content>
    </mat-card>

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('meals_footer' | translate) !== 'meals_footer'">
        <mat-card-content>
            <p [innerHTML]="'meals_footer' | translate"></p>
        </mat-card-content>
    </mat-card>
    
</div>