<div class="content-centered">

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('settings_header' | translate) !== 'settings_header'">
        <mat-card-content>
            <p [innerHTML]="'settings_header' | translate"></p>
        </mat-card-content>
    </mat-card>

    <mat-card class="info-card">
        <mat-card-content>

            <mat-form-field>
                <mat-label>{{ 'Sprache' | translate}}</mat-label>
                <mat-select placeholder="{{ 'Sprache' | translate}}" [(ngModel)]="globals.language" name="language" (selectionChange)="setLanguage()">
                    <mat-option [value]="'de'">{{ 'Deutsch' | translate }}</mat-option>
                    <!--
                    <mat-option [value]="'en'">{{ 'Englisch' | translate }}</mat-option>
                    <mat-option [value]="'fr'">{{ 'Französisch' | translate }}</mat-option>
                    -->
                </mat-select>
            </mat-form-field>

            <div *ngIf="pushService.subscriptionType() !== 'none'">
                <div *ngIf="pushService.canSubscribe$ | async; else unsubscribe">
                    <p>
                        {{ 'Aktivieren Sie die Benachrichtigungen um die neusten Nachrichten zeitnahe zu bekommen.' | translate }}
                    </p>
                    <p>
                        <button mat-button color="primary" (click)="registerPush()">{{ 'Benachrichtigungen erlauben' | translate }}</button>
                    </p>
                </div>

                <ng-template #unsubscribe>
                    <div *ngIf="pushService.subscriptionType() === 'devPush'">
                        Auf localhost sind keine Benachrichtigungen möglich, dies ist ein Dummy-Push-Type um das senden und löschen der Token zu testen.<br>
                        Diese Meldung wird auch nur auf localhost angezeigt.
                    </div>
                    <p>
                        <button mat-button color="primary" (click)="unregisterPush()">{{ 'Benachrichtigungen deaktivieren' | translate }}</button>
                    </p>
                </ng-template>
            </div>

        </mat-card-content>
    </mat-card>

    <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
    <mat-card class="info-card" *ngIf="('settings_footer' | translate) !== 'settings_footer'">
        <mat-card-content>
            <p [innerHTML]="'settings_footer' | translate"></p>
        </mat-card-content>
    </mat-card>

</div>