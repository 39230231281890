<div class="content-centered">

  <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
  <mat-card class="info-card" *ngIf="('contract_list_header' | translate) !== 'contract_list_header'">
    <mat-card-content>
        <p [innerHTML]="'contract_list_header' | translate"></p>
    </mat-card-content>
  </mat-card>

  <app-table (rowClicked)="loadContract($event)" (newClicked)="newContract()"></app-table>

  <!--  Nur Anzeigen falls eine entsprechende Übersetzung geführt wird  -->
  <mat-card class="info-card" *ngIf="('contract_list_footer' | translate) !== 'contract_list_footer'">
    <mat-card-content>
        <p [innerHTML]="'contract_list_footer' | translate"></p>
    </mat-card-content>
  </mat-card>

</div>